import React, { ReactElement, useEffect, useRef, useState } from "react";
import { Loader } from "react-feather";
import { useHistory } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import Axios from "axios";
import ReactGA from "react-ga";
import { animateScroll as scroll } from "react-scroll";
import { Breadcrums, Header, Modal } from "../../../components";
import {
  ConsentModal,
  ExpiredQuestionnaireModal,
  MedicalRecommendationModal,
} from "../../../modals";
import useLanguage from "../../../hooks/language";
import useAuthUser from "../../../hooks/auth-user";
import User, {
  hasCompletedQuestionnaireWithoutScheduling,
  hasPaidLastQuestionnaire,
  upcomingScreeningAppointment,
  expiredPendingQuestionnaire,
} from "../../../models/user";
import SuggestedMedicalTest from "../../../models/suggested-medical-test";
import ENDPOINTS from "../../../utils/endpoints";
import ROUTES from "../../../utils/routes";

import PersonalInfo from "./personal-info";
import MedicalEvaluation from "./medical-evaluation";
import Payment from "./payment";
import Recommendations from "./recommendations";
import DateSelection from "./date-selection";

import styles from "./screening.module.css";

export interface ScreeningPage {
  content: ReactElement;
  title: string;
}

const getDefaultIndex = (user: User | null) => {
  if (
    user &&
    hasCompletedQuestionnaireWithoutScheduling(user) &&
    !expiredPendingQuestionnaire(user)
  ) {
    if (user.hasToPay && hasPaidLastQuestionnaire(user)) {
      return 3;
    }
    return 2;
  }
  return 0;
};

const ScreeningPage: React.FunctionComponent = () => {
  const { push } = useHistory();
  const { translations } = useLanguage();
  const [authUser, setAuthUser] = useAuthUser();
  const t = translations.appointmentsSection;
  const tTitles = translations.sectionSelection.makeScreeningSection;
  const tModal = translations.questionnaireSection.modal;
  const tExpired = translations.expiredSection;

  const upcomingAppointment = authUser
    ? upcomingScreeningAppointment(authUser)
    : undefined;

  // Refs

  const containerRef = useRef<HTMLDivElement | null>(null);

  // States

  const [consentModalVisible, setConsentModalVisible] = useState(false);

  // Not to clean but otherwise the modal is shown twice and I don't like it much
  const [recommendationModalVisible, setRecommendationModalVisible] = useState(
    false
  );
  const [
    expiredQuestionnaireModalVisible,
    setExpiredQuestionnaireModalVisible,
  ] = useState(false);
  // Not to clean but otherwise the modal is shown twice and I don't like it much
  const [
    expiredQuestionnaireModalHasBeenShown,
    setExpiredQuestionnaireModalHasBeenShown,
  ] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(getDefaultIndex(authUser));
  const [minIndex, setMinIndex] = useState(getDefaultIndex(authUser));
  const [maxIndex, setMaxIndex] = useState(getDefaultIndex(authUser));
  const [selectedMedicalTests, setSelectedMedicalTests] = useState<
    SuggestedMedicalTest[] | undefined
  >();

  const [isLoading, setIsLoading] = useState(false);

  // Effects

  useEffect(() => {
    setCurrentIndex(getDefaultIndex(authUser));
    setMinIndex(getDefaultIndex(authUser));
    setMaxIndex(getDefaultIndex(authUser));

    if (
      authUser &&
      expiredPendingQuestionnaire(authUser) &&
      !expiredQuestionnaireModalHasBeenShown
    ) {
      setExpiredQuestionnaireModalVisible(true);
      setExpiredQuestionnaireModalHasBeenShown(true);
    }
  }, [authUser]);

  useEffect(() => {
    if (expiredQuestionnaireModalVisible)
      setExpiredQuestionnaireModalHasBeenShown(true);
  }, [expiredQuestionnaireModalVisible]);

  useEffect(() => {
    if (!containerRef.current) return;
    scroll.scrollTo(containerRef.current.offsetTop - 100, { duration: 500 });
  }, [currentIndex]);

  // Handlers

  const onConsent = () => {
    setConsentModalVisible(false);
    setRecommendationModalVisible(true);
    setCurrentIndex(1);
    setMaxIndex(1);

    ReactGA.event({
      category: "Questionnaire",
      action: "Start medical questionnaire",
    });
  };

  const onCompleteQuestionnaire = () => {
    setCurrentIndex(2);
    setMinIndex(2);
    setMaxIndex(2);

    ReactGA.event({
      category: "Questionnaire",
      action: "Complete Medical Questionnaire",
    });
  };

  const onCompletePayment = () => {
    setCurrentIndex(3);
    setMinIndex(3);
    setMaxIndex(3);

    ReactGA.event({
      category: "Questionnaire",
      action: "Purchase Questionnaire",
    });
  };

  const onRestartQuestionnaireClick = () => {
    setCurrentIndex(0);
    setMinIndex(0);
    setMaxIndex(0);

    const user = { ...authUser };
    user.lastUserQuestionnaire = undefined;
    user.hasToPay = false;
    setAuthUser(user as User);
  };

  const onSelectMedicalTests = (tests: SuggestedMedicalTest[]) => {
    setSelectedMedicalTests(tests);
    setCurrentIndex(currentIndex + 1);
    setMaxIndex(maxIndex + 1);
  };

  // Network

  const bookAppointment = async (datetime: string, clinicId: number) => {
    if (!authUser || !selectedMedicalTests) return;

    setIsLoading(true);

    const { lastUserQuestionnaireId: userQuestionnaireId } = authUser;
    const chosenMedicalTests = selectedMedicalTests.map(({ medicalTest }) => ({
      medicalTestId: medicalTest.id,
    }));

    const body = {
      userQuestionnaireId,
      chosenMedicalTests,
      datetime,
      clinicId,
    };

    try {
      await Axios.post(ENDPOINTS.APPOINTMENTS, body);
      const { data } = await Axios.get(ENDPOINTS.ME);
      setAuthUser(data);
      NotificationManager.success(tTitles.appointmentCreated);
      push(ROUTES.HOME);
    } catch {
      setIsLoading(false);
    }
  };

  // Rendering

  const isLoadingContent = authUser === null || authUser === undefined;

  const pages: ScreeningPage[] = [
    {
      title: tTitles.information,
      content: (
        <div className={styles.page} key={0}>
          <PersonalInfo onSubmit={() => setConsentModalVisible(true)} />
        </div>
      ),
    },
    {
      title: tTitles.medicalEvaluation,
      content: (
        <div className={styles.page} key={1}>
          <MedicalEvaluation onSubmit={onCompleteQuestionnaire} />
        </div>
      ),
    },
  ];

  const expiredQuestionnaire =
    authUser && expiredPendingQuestionnaire(authUser);
  const hasAlreadyPaid =
    expiredQuestionnaire && hasPaidLastQuestionnaire(authUser!);
  const hasToPay =
    authUser && authUser.hasToPay && !upcomingAppointment && !hasAlreadyPaid;

  if (hasToPay) {
    pages.push({
      title: tTitles.payment,
      content: (
        <div className={styles.page} key={2}>
          <Payment onCompletePayment={onCompletePayment} />
        </div>
      ),
    });
  }

  pages.push({
    title: tTitles.recommendations,
    content: (
      <div className={styles.page} key={3}>
        <Recommendations
          upcomingAppointment={upcomingAppointment}
          selectedMedicalTests={selectedMedicalTests}
          onRestartQuestionnaireClick={onRestartQuestionnaireClick}
          onSelectMedicalTests={onSelectMedicalTests}
        />
      </div>
    ),
  });

  if (!upcomingAppointment) {
    pages.push({
      title: tTitles.date,
      content: (
        <div className={styles.page} key={4}>
          <DateSelection onSubmit={bookAppointment} loading={isLoading} />
        </div>
      ),
    });
  }

  const loadingElement = (
    <p className={styles.loader}>
      <Loader className="spinorama" />
      {t.loadingInfo}
    </p>
  );

  // Fix smoll crash for the small laps of time after user created appointment so there is an upcoming appointment so less pages
  let contentIndex = currentIndex;
  if (currentIndex >= pages.length) contentIndex = pages.length - 1;

  return (
    <div ref={containerRef}>
      {/* Modals */}
      <Modal
        title={tModal.consentHeader}
        visible={consentModalVisible}
        onVisibilityChange={setConsentModalVisible}
        className={styles.consentModal}
      >
        <ConsentModal
          onClose={() => setConsentModalVisible(false)}
          onConsent={onConsent}
        />
      </Modal>
      <Modal
        title={tModal.recommendationHeader}
        visible={recommendationModalVisible}
        onVisibilityChange={setRecommendationModalVisible}
      >
        <MedicalRecommendationModal
          onClose={() => setRecommendationModalVisible(false)}
        />
      </Modal>
      <Modal
        title={tExpired.header}
        visible={expiredQuestionnaireModalVisible}
        onVisibilityChange={setExpiredQuestionnaireModalVisible}
      >
        <ExpiredQuestionnaireModal
          onClose={() => setExpiredQuestionnaireModalVisible(false)}
        />
      </Modal>

      <Header />

      {/* Page content */}

      {isLoadingContent && <div className="container">{loadingElement}</div>}
      {!isLoadingContent && (
        <Breadcrums
          crumTitles={pages.map(({ title }) => title)}
          currentIndex={currentIndex}
          maxIndex={maxIndex}
          minIndex={minIndex}
          onClickIndex={setCurrentIndex}
        />
      )}

      {!isLoadingContent && (
        <div className={styles.pageHolder}>{pages[contentIndex].content}</div>
      )}
    </div>
  );
};

export default ScreeningPage;
