import React from "react";
import { Calendar, Phone } from "react-feather";
import { DateTime } from "luxon";
import { Button } from "..";
import PhoneAppointment, {
  isCanceled,
  isMissed,
  isUpcoming,
  getLinkedAppointmentId,
  isRejected,
} from "../../models/phone-appointment";
import useAuthUser from "../../hooks/auth-user";
import useLanguage from "../../hooks/language";

import styles from "./appointment-card.module.css";

interface PhoneAppointmentCardProps {
  phoneAppointment: PhoneAppointment;
  onChooseDateClick?: () => void;
  onRejectClick?: () => void;
}

const PhoneAppointmentCard: React.FunctionComponent<PhoneAppointmentCardProps> = ({
  phoneAppointment,
  onChooseDateClick,
  onRejectClick,
}: PhoneAppointmentCardProps) => {
  const [authUser] = useAuthUser();
  const { translations, language } = useLanguage();
  const t = translations.appointmentsSection.phoneAppointmentCard;

  // Rendering

  const { startAt } = phoneAppointment;

  // Local date time string
  const date = startAt ? DateTime.fromISO(startAt) : null;
  const day = date?.day;
  const shortMonth = date?.setLocale(language).toFormat("MMM");

  // Scheduled
  const scheduled = date !== null;

  // Upcoming
  const upcoming = isUpcoming(phoneAppointment);

  // Canceled
  const canceled = isCanceled(phoneAppointment);

  // Missed
  const missed = isMissed(phoneAppointment);

  // rejected
  const rejected = isRejected(phoneAppointment);

  // Can choose date time
  const canChooseDateTime = upcoming || missed;

  // Associated appointment
  const linkedAppointmentId = getLinkedAppointmentId(phoneAppointment);
  const linkedAppointment = authUser?.appointments.find(
    ({ id }) => id === linkedAppointmentId
  );
  const linkedAppointmentDate = linkedAppointment
    ? DateTime.fromISO(linkedAppointment.datetime)
    : null;

  // Can reject
  const canReject = !rejected && !linkedAppointment && isUpcoming;

  // Titles
  const title = date
    ? t.dateIndicator(
        date.setLocale(language).toLocaleString(DateTime.DATETIME_MED)
      )
    : t.title;

  return (
    <div
      className={`${styles.holder} ${
        canceled || rejected ? styles.canceled : ""
      }`}
    >
      {/* Card header */}
      <div className={styles.header}>
        <Phone
          className={missed || (!scheduled && !rejected) ? "urgent" : ""}
        />
        <h2>{title}</h2>
      </div>

      <div className={styles.content}>
        <div className={styles.flexHolder}>
          <div className={styles.left}>
            {/* Date indicator */}
            <div className={styles.dateHolder}>
              <Calendar />
              {day !== null && <span className={styles.day}>{day}</span>}
              {shortMonth !== null && (
                <span className={styles.month}>{shortMonth}</span>
              )}
              {!date && <span className={styles.what}>?</span>}
            </div>

            {/* Buttons */}
            {canChooseDateTime && (
              <Button type="secondary" onClick={onChooseDateClick}>
                {scheduled && !missed ? t.editButtonText : t.chooseButtonText}
              </Button>
            )}
            {canReject && (
              <Button type="secondary" onClick={onRejectClick}>
                {t.rejectButtonText}
              </Button>
            )}
          </div>

          <div className={styles.right}>
            {rejected && <p>{t.rejectedIndicator}</p>}
            {missed && <p className="urgent">{t.missedMessage}</p>}
            {!scheduled && !rejected && (
              <p className="urgent">{t.chooseDate}</p>
            )}
            {linkedAppointmentDate !== null && (
              <p>
                {t.descriptionLine1(
                  linkedAppointmentDate
                    .setLocale(language)
                    .toLocaleString(DateTime.DATE_MED)
                )}
              </p>
            )}
            {upcoming && <p>{t.descriptionLine2}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhoneAppointmentCard;
