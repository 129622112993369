import MedicalTest, { getLocaleName } from "./medical-test";

export type ChosenMedicalTestStatus = "TAKEN" | "REFUSED" | "PENDING";
export type ChosenMedicalTestResult =
  | "ABNORMAL"
  | "NORMAL"
  | "RETAKE"
  | "UNDISCLOSED";

export default interface ChosenMedicalTest {
  id: number;
  status: ChosenMedicalTestStatus;
  medicalTest: MedicalTest;
  result: ChosenMedicalTestResult;
}

// Utils methods

export const sortChosenMedicalTests = (
  chosenMedicalTests: ChosenMedicalTest[],
  locale: string
): ChosenMedicalTest[] =>
  chosenMedicalTests.sort((a: ChosenMedicalTest, b: ChosenMedicalTest) =>
    getLocaleName(a.medicalTest, locale) > getLocaleName(b.medicalTest, locale)
      ? 1
      : -1
  );
