import React from "react";
import useLanguage from "../../hooks/language";
import { Button } from "../../components";

interface MedicalRecommendationModalProps {
  onClose?: () => void;
}

const MedicalRecommendationModal: React.FunctionComponent<MedicalRecommendationModalProps> = ({
  onClose,
}: MedicalRecommendationModalProps) => {
  const { translations } = useLanguage();
  const t = translations.questionnaireSection.modal;

  // Rendering

  return (
    <div>
      <p>{t.recommendationSubHeader}</p>
      <ul>
        <li
          dangerouslySetInnerHTML={{
            __html: t.recommendationFirstP,
          }}
        />
        <li
          dangerouslySetInnerHTML={{
            __html: t.recommendationSecondP,
          }}
        />
        <li>{t.recommendationThirdP}</li>
      </ul>
      <div className="dual-button-holder">
        <Button onClick={onClose}>{t.continueButtonText}</Button>
      </div>
    </div>
  );
};

export default MedicalRecommendationModal;
