import React from "react";
import { Check } from "react-feather";
import styles from "./select.module.css";

interface OptionInterface {
  value: string | number;
  display?: string;
  data?: any;
  children?: React.ReactNode;
  active?: boolean;
  selected?: boolean;
  onClick?: () => void;
  onHover?: () => void;
}

const Option: React.FunctionComponent<OptionInterface> = ({
  value,
  active = false,
  selected = false,
  children,
  onClick,
  onHover,
}: OptionInterface) => {
  // Handlers

  const onMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    if (onClick) onClick();
  };

  // Rendering

  let className = styles.option;
  if (active) className += ` ${styles.active}`;

  return (
    <div className={className} onMouseDown={onMouseDown} onMouseEnter={onHover}>
      <span>{children ? children : value}</span>
      {selected && <Check />}
    </div>
  );
};

export default Option;
