import React, { useEffect, useState } from "react";
import Axios from "axios";
import { NotificationManager } from "react-notifications";
import useLanguage from "../../hooks/language";
import { Button } from "../../components";
import Appointment, { canEdit, shouldRetake } from "../../models/appointment";
import ENDPOINTS from "../../utils/endpoints";
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm from "./payment-form";
import { loadStripe } from "@stripe/stripe-js";
import ClinicList from "../../components/clinic-list";
import Clinic from "../../models/clinic";

interface ScheduleAppointmentModalProps {
  onClose?: () => void;
  onConfirm?: () => void;
  appointment?: Appointment;
  isVisible?: boolean;
}

const PUBLIC_STRIPE_KEY = process.env.REACT_APP_PUBLIC_STRIPE_KEY || "null";
const stripePromise = loadStripe(PUBLIC_STRIPE_KEY);

const ScheduleAppointmentModal: React.FunctionComponent<ScheduleAppointmentModalProps> = ({
  appointment,
  onClose,
  onConfirm,
  isVisible,
}: ScheduleAppointmentModalProps) => {
  const { translations, language } = useLanguage();
  const t = translations.appointmentsSection;

  // states

  const [loading, setLoading] = useState<boolean>(false);
  const [isoDate, setIsoDate] = useState<string | null>(null);
  const [showPaymentForm, setShowPaymentForm] = useState<boolean>(false);
  const [clinics, setClinics] = useState<Clinic[]>([]);
  const [selectedClinic, setSelectedClinic] = useState<number | null>(null);

  //This is in order to reset the form payment state on click outside
  useEffect(() => {
    if (!isVisible) setShowPaymentForm(false);
    else fetchClinics();
  }, [isVisible]);

  // Handlers

  const onSubmit = () => {
    if (!appointment) return;
    if (canEdit(appointment)) {
      updateAppointment();
    } else if (shouldRetake(appointment)) {
      bookRetake();
    }
  };

  // Network
  const fetchClinics = async () => {
    try {
      const { data } = await Axios.get(ENDPOINTS.CLINICS);
      setClinics(data);
    } catch (error) {
      console.log(error);
      NotificationManager.error(error);
    }
  };

  const updateAppointment = async () => {
    if (!appointment) return;

    setLoading(true);

    try {
      await Axios.put(ENDPOINTS.APPOINTMENT(appointment.id), {
        datetime: isoDate,
        clinicId: selectedClinic,
      });
      if (onConfirm) await onConfirm();
      NotificationManager.success(
        translations.notification.rescheduledAppointment
      );
    } finally {
      setShowPaymentForm(false);
      setLoading(false);
    }
  };

  const bookRetake = async () => {
    if (!appointment) return;

    setLoading(true);

    try {
      await Axios.post(ENDPOINTS.BOOK_RETAKE_APPOINTMENT, {
        appointmentToRetakeId: appointment.id,
        datetime: isoDate,
        clinicId: selectedClinic,
      });
      if (onConfirm) await onConfirm();
      NotificationManager.success(
        translations.sectionSelection.makeScreeningSection.appointmentCreated
      );
    } finally {
      setShowPaymentForm(false);
      setLoading(false);
    }
  };

  // Rendering

  return (
    <div>
      {!showPaymentForm ? (
        <ClinicList
          clinics={clinics}
          onDateSelect={setIsoDate}
          onClinicSelect={setSelectedClinic}
          hasDateSelect={true}
        />
      ) : (
        <Elements stripe={stripePromise} options={{ locale: language }}>
          <PaymentForm
            appointment={appointment}
            datetime={isoDate}
            clinicId={selectedClinic}
            onCompletePayment={onConfirm}
            onClose={() => setShowPaymentForm(false)}
          />
        </Elements>
      )}
      <br />
      <div className="dual-button-holder">
        {appointment?.canRescheduleFreely && (
          <>
            <Button onClick={onClose} type="secondary">
              {t.cancellationModal.exitButtonText}
            </Button>
            <Button
              loading={loading}
              disabled={!isoDate || !selectedClinic}
              onClick={onSubmit}
            >
              {t.cancellationModal.confirmButtonText}
            </Button>
          </>
        )}
        {!appointment?.canRescheduleFreely && !showPaymentForm && (
          <>
            <Button onClick={onClose} type="secondary">
              {t.cancellationModal.exitButtonText}
            </Button>
            <Button
              disabled={!isoDate || !selectedClinic}
              loading={loading}
              onClick={() => setShowPaymentForm(true)}
            >
              {t.cancellationModal.payButton}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default ScheduleAppointmentModal;
