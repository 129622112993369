import { getPersistedUserLanguage } from "../utils/local-storage";
import { UserLanguage } from "../utils/types";

import fr from "./fr";
import en from "./en";

export const getTranslations = (
  language: UserLanguage = getPersistedUserLanguage()
) => {
  if (language == "fr") {
    return fr;
  } else if (language == "en") {
    return en;
  } else {
    throw new Error("Not a valid language");
  }
};
