import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import useLanguage from "../../hooks/language";
import useAuthUser from "../../hooks/auth-user";
import { Field, Button } from "../../components";
import ENDPOINTS from "../../utils/endpoints";
import ROUTES from "../../utils/routes";

import styles from "./confirm-phone-number.module.css";

interface ConfirmPhoneNumberModalProps {
  visible: boolean;
  phoneNumber: string;
  onConfirm?: () => void;
  linkToProfile?: boolean;
}

const ConfirmPhoneNumberModal: React.FunctionComponent<ConfirmPhoneNumberModalProps> = ({
  visible,
  phoneNumber,
  onConfirm,
  linkToProfile = false,
}: ConfirmPhoneNumberModalProps) => {
  const { push } = useHistory();
  const [authUser] = useAuthUser();
  const { translations } = useLanguage();
  const t = translations.phoneConfirmationModal;

  // States

  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [phoneConfirmationToken, setPhoneConfirmationToken] = useState("");
  const [code, setCode] = useState("");

  // Effects

  useEffect(() => {
    if (visible && authUser) requestPhoneConfirmationCode();
  }, [visible, authUser]);

  // Validations

  const tfaError = code.length < 6 ? t.invalid : null;
  const disabled = code.length === 0 || (submitted && tfaError !== null);

  // Handlers

  const onSubmit = () => {
    if (disabled) return;
    setSubmitted(true);
    confirmCode();
  };

  // Networks

  const requestPhoneConfirmationCode = async () => {
    const { data } = await Axios.put(
      ENDPOINTS.REQUEST_PHONE_CONFIRMATION_CODE,
      { phoneNumber }
    );
    setPhoneConfirmationToken(data.phoneConfirmationToken);
  };

  const confirmCode = async () => {
    if (!authUser) return;

    setLoading(true);

    try {
      const body = { phoneConfirmationToken, confirmationCode: code };
      const { data } = await Axios.put(ENDPOINTS.CONFIRM_PHONE_NUMBER, body);
      setPhoneConfirmationToken(data.phoneConfirmationToken);
      setSubmitted(false);
      setCode("");
      NotificationManager.success(t.successMessage);
      if (onConfirm) onConfirm();
    } finally {
      setLoading(false);
    }
  };

  // Rendering

  return (
    <div>
      <p>{t.sentTo(phoneNumber)}</p>

      <label>{t.instructions}</label>
      <Field
        placeholder={t.placeholder}
        value={code}
        onChange={(e) => setCode(e.target.value)}
        onPressEnter={onSubmit}
        error={submitted ? tfaError : null}
      />
      <Button
        loading={loading}
        onClick={onSubmit}
        disabled={disabled}
        className={styles.button}
      >
        {t.submit}
      </Button>
      <div className={styles.disclaimers}>
        {linkToProfile && (
          <p className="disclaimer" onClick={() => push(ROUTES.PROFILE)}>
            {t.wrongPhone} <a>{t.changePhone}</a>
          </p>
        )}
        <p className="disclaimer" onClick={requestPhoneConfirmationCode}>
          {t.notReceived} <a>{t.resend}</a>
        </p>
      </div>
    </div>
  );
};

export default ConfirmPhoneNumberModal;
