import { DateTime } from "luxon";
import React, { useState, useEffect } from "react";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import useLanguage from "../../hooks/language";
import useAuthUser from "../../hooks/auth-user";
import { Footer, Modal, Header } from "../../components";
import { isScheduled } from "../../models/appointment";
import { geLastAppointment } from "../../models/user";
import { ConfirmPhoneNumberModal } from "../../modals";
import ROUTES from "../../utils/routes";

import styles from "./home.module.css";

import appointmentsIconSrc from "../../assets/images/appointments-icon.svg";
import screeningIconSrc from "../../assets/images/screening-icon.svg";
import ScreeningPage from "./screening";
import MyAppointments from "./my-appointments";

const HomePage: React.FunctionComponent = () => {
  const [authUser, setAuthUser] = useAuthUser();
  const { translations, language } = useLanguage();
  const tHome = translations.homePage;
  const tWelcome = translations.sectionSelection;

  // Effects
  useEffect(() => {
    if (!authUser) return;

    // If user tfa pref is sms and phone number is not confirmed, user needs to be prompted
    if (authUser.tfaPreference === "sms" && !authUser.phoneNumberConfirmed) {
      setConfirmationModalVisible(true);
    }
  }, [authUser]);

  const [confirmationModalVisible, setConfirmationModalVisible] = useState(
    false
  );

  // Handlers
  const onConfirmPhoneNumber = () => {
    setConfirmationModalVisible(false);
    if (authUser) setAuthUser({ ...authUser, phoneNumberConfirmed: true });
  };

  // Welcome string
  let welcomeString = tWelcome.welcomeTextBasic;
  let lastAppointmentIsScheduled = false;
  if (authUser) {
    const lastAppointment = geLastAppointment(authUser);
    if (lastAppointment) {
      const dateString = DateTime.fromISO(lastAppointment.datetime)
        .setLocale(language)
        .toLocaleString(DateTime.DATE_MED);

      lastAppointmentIsScheduled = isScheduled(lastAppointment);
      welcomeString = lastAppointmentIsScheduled
        ? `${tWelcome.welcomeTextReminder} ${dateString}`
        : `${tWelcome.welcomeTextInfo} ${dateString}`;
    }
  }

  return (
    <div>
      {/* Phone confirmation modal */}
      <Modal
        title={translations.phoneConfirmationModal.title}
        visible={confirmationModalVisible}
        onVisibilityChange={setConfirmationModalVisible}
      >
        <ConfirmPhoneNumberModal
          visible={confirmationModalVisible}
          phoneNumber={authUser ? authUser.phone : ""}
          onConfirm={onConfirmPhoneNumber}
          linkToProfile={true}
        />
      </Modal>
      <Header />
      <div className={styles.header}>
        <div className={styles.myFile}>
          <p>{tHome.logo.myDirectoryDecorator}</p>
        </div>
        <div className={styles.navigation}>
          <p>{welcomeString}</p>
          <div>
            <Link to={`${ROUTES.APPOINTMENTS}`}>
              <button>
                <img
                  alt={tHome.subheaderTab.myAppointmentTitle}
                  src={appointmentsIconSrc}
                />
                <h3> {tHome.subheaderTab.myAppointmentTitle} </h3>
              </button>
            </Link>
            <Link
              onClick={(e) =>
                lastAppointmentIsScheduled ? e.preventDefault() : ""
              }
              to={`${ROUTES.SCREENING}`}
              className={`${
                lastAppointmentIsScheduled ? styles.disabledLink : ""
              }`}
            >
              <button>
                <div disabled-tool-tip={`${tHome.tooltipTab.screening}`}>
                  <img
                    alt={tHome.subheaderTab.screeningTitle}
                    src={screeningIconSrc}
                  />
                </div>
                <h3> {tHome.subheaderTab.screeningTitle} </h3>
              </button>
            </Link>
          </div>
        </div>
      </div>
      <Switch>
        <Route exact path={`${ROUTES.APPOINTMENTS}`}>
          <MyAppointments />
        </Route>
        <Route path={`${ROUTES.SCREENING}`}>
          <ScreeningPage />
        </Route>
        <Redirect to={`${ROUTES.APPOINTMENTS}`} />
      </Switch>

      <Footer />
    </div>
  );
};

export default HomePage;
