import React, { useState, useEffect } from "react";
import { DateTime } from "luxon";
import CalendarPicker from "./calendar";
import { Field } from "..";
import TimePicker from "./time-picker";
import useLanguage from "../../hooks/language";

import styles from "./datetime-select.module.css";

interface DatetimePickerProps {
  className?: string;
  openedDates?: string[];
  availableTimeSlots?: string[];
  minDate?: Date;
  maxDate?: Date;
  onDateChange?: (date: Date) => void;
  onTimeChange?: (time: string | undefined) => void;
  onCalendarNavigation?: (firstActiveDay: Date) => void;
}

const DatetimePicker: React.FunctionComponent<DatetimePickerProps> = ({
  className,
  openedDates,
  availableTimeSlots,
  minDate,
  maxDate,
  onDateChange,
  onTimeChange,
  onCalendarNavigation,
}: DatetimePickerProps) => {
  const { language, translations } = useLanguage();
  const t = translations.dateSelectorComponent;

  // States

  const [selectedDate, setSelectedDate] = useState<Date | undefined>();

  // Effects

  useEffect(() => {
    if (onDateChange && selectedDate) onDateChange(selectedDate);
  }, [selectedDate]);

  // Rendering

  const dateString = selectedDate
    ? DateTime.fromJSDate(selectedDate)
        .setLocale(language)
        .toLocaleString(DateTime.DATE_MED)
    : undefined;

  return (
    <div className={`${styles.holder} ${className ?? ""}`}>
      <div className={`${styles.calendarCol}`}>
        <Field
          className={styles.w100}
          placeholder={t.choseDateLabel}
          value={dateString}
          readOnly={true}
        />
        <div className={styles.calendarHolder}>
          <CalendarPicker
            minDate={minDate}
            maxDate={maxDate}
            onDateChange={setSelectedDate}
            openedDates={openedDates}
            onCalendarNavigation={onCalendarNavigation}
          />
        </div>
      </div>
      <div className={`${styles.timeCol}`}>
        <TimePicker
          onSelectTime={onTimeChange}
          labelText={t.choseHourLabel}
          availableTimeSlots={availableTimeSlots}
        />
      </div>
    </div>
  );
};
export default DatetimePicker;
