export default {
  appointmentsSection: {
    loadingInfo: "Loading your info",
    upcomingAppointments: "Your upcoming appointments",
    pastAppointments: "Your past appointments",
    noAppointment: "You have no appointment yet",
    clickHereToSchedule: "Click here to conduct a screening",
    appointmentCard: {
      cancelButtonText: "Cancel",
      editButtonText: "Modify",
      rescheduleButton: "Reschedule",
      retakeQuestionnaire: "Redo the questionnaire",
      cancellationIndicator: "CANCELLED",
      staffCancellationIndicator: "MUST BE RESCHEDULED",
      consultButtonText: "Consult",
      dateIndicator: (date) => `${date} screening appointment `,
      medicalTestsIndicator: "Tests required",
      resultsIndicator: "Your results",
      missedMessage:
        "You have missed your appointment, but you still have time to reschedule it.",
      resultsNotReadyLine1: "Your results have not arrived yet,",
      resultsNotReadyLine2: "but it won't be much longer.",
      missedAppointmentMessage:
        "You have missed your appointment and your consultation has expired. To schedule an appointment, complete a new online medical consultation.",
    },
    phoneAppointmentCard: {
      chooseButtonText: "Choose",
      editButtonText: "Change",
      consultButtonText: "Consult",
      rejectButtonText: "Reject",
      title: "Phone appointment",
      independantTitle: "Phone appointment",
      rejectedIndicator: "REJECTED",
      dateIndicator: (date) => `${date} phone appointment`,
      independantDateIndicator: (date) => `${date} phone appointment`,
      descriptionLine1: (assAppDate) =>
        `Phone appointment regarding the ${assAppDate} screening appointment.`,
      descriptionLine2:
        "A member of the staff will contact you during the time slot reserved for the appointment.",
      missedMessage:
        "You have missed your phone appointment, but you can reschedule it.",
      chooseDate:
        "You have been invited to a phone appointment. Choose a date.",
    },
    cancellationModal: {
      cancellationButtonText: "Yes, I want to cancel my appointment*",
      confirmButtonText: "Confirm",
      exitButtonText: "Cancel",
      header: (date) => `Update your ${date} appointment`,
      keepButtonText: "No, I want to keep my appointment",
      payButton: "Pay",
      rescheduleButtonText: "I would like to choose another date",
      feesMightApply: "*Certain fees may apply.",
      faqUrl: "https://www.prelib.com/en?faq=14#faqs",
      appointmentCanceled: "The appointment was canceled",
      phoneAppointmentRejected: "The appointment was rejected",
    },
    cancellationConfirmModal: {
      title: "Cancelling your appointment will be irreversible!",
      phoneTitle: "Cancelling your phone appointment will be irreversible!",
      content:
        "If you cancel your appointment, fees related to the online medical consultation cannot be reimbursed.",
    },
    creditInfoModal: {
      cardBillingAddressLabel: "Billing address",
      cardExpirationLabel: "Expiry",
      cardHolderNameLabel: "Name on card",
      content:
        "Please confirm your credit card information for payment of\u00A0",
      modifyButton: "Modify",
      link: "administrative fees of $15 (taxes extra)",
      faqUrl: "https://www.prelib.com/en?faq=14#faqs",
      payButton: "Pay",
    },
    gatherCreditInfoModal: {
      content: "Please enter your credit card information for payment of\u00A0",
      link: "administrative fees  of $ 15 (taxes extra)",
    },
    rescheduleAppointmentModal: {
      title: "Select a date",
      confirmButtonText: "Select",
      exitButtonText: "Cancel",
    },
    feedbackModal: {
      title: "Satisfaction questionnaire",
      confirmButtonText: "Submit",
      letUsKnowHowItWent: "Let us know how your experience at Prélib was",
      satisfactionLevel:
        "What is your level of satisfaction with the Prélib service?",
      recommendationProbability:
        "How likely are you to recommend Prélib to your friends or colleagues?",
      recommendationJustification:
        "In your own words, why did you provide this rating?",
      textAreaPlaceholder: "Please write a note ...",
      phoneCommunicationAgreement:
        "In order to improve our services, would you accept that a member of our team contact you by phone?",
      yes: "Yes",
      no: "No",
    },
  },
  dateSection: {
    header: "Select your clinic and your time slot",
    submitButtonText: "Submit",
  },
  dateSelectorComponent: {
    choseDateLabel: "Select a date",
    choseHourLabel: "Time",
    noAvailability: "No availability",
    noDateAvailability:
      "No dates available at this time. Please come back tomorrow.",
  },
  unsubscribeConfirmation: {
    goToHomeButton: "View my profile",
    header: "Confirmation unsubscription",
    invalidTokenError: "The token is not valid!",
    successMessage:
      "You have successfully unsubscribed from email communications. You can resubscribe through your online profile by clicking on the “Subscribe to follow-up emails” button in the Personal Information section.",
  },
  satisfactionQuestionnaire: {
    title: "Satisfaction questionnaire",
    letUsKnowHowItWent: "Let us know how your experience at Prélib was",
    satisfactionLevel:
      "What is your level of satisfaction with the Prélib service?",
    recommendationProbability:
      "How likely are you to recommend Prélib to your friends or colleagues?",
    recommendationJustification:
      "In your own words, why did you provide this rating?",
    phoneCommunicationAgreement:
      "In order to improve our services, would you accept that a member of our team contact you by phone?",
    yes: "Yes",
    no: "No",
    submit: "Submit",
  },
  expiredSection: {
    header: "Your questionnaire has expired.",
    restart: "Redo the questionnaire",
    message:
      "Your questionnaire has expired.\n\nThe 45-day deadline for making an appointment has passed. Your medical questionnaire is therefore no longer valid, and a complete re-evaluation is necessary.\\n\\nIf you have any questions, contact us at info@prelib.com, and a member of our team will get back to you shortly.",
  },
  emailConfirmation: {
    goToHomeButton: "View my profile",
    header: "Confirmation of e-mail address",
    invalidTokenError: "The token is not valid!",
    successMessage: "Your e-mail address has been confirmed!",
  },
  footer: {
    cityAddress: "Montreal, Quebec  H2L 4L8",
    civicAddress: "1485, rue Saint-Hubert",
    disclaimer: "@2020 Prélib. All rights reserved.",
    emailLabel: "E",
    weekendLabel: "S",
    beginningOfWeekLabel: "M - W",
    endOfWeekLabel: "T - F",
    schedule: [
      {
        title: "M: ",
        content: "7 a.m. to 8 p.m.",
      },
      {
        title: "Tu: ",
        content: "7 a.m. to 8 p.m.",
      },
      {
        title: "Wed: ",
        content: "7 a.m. to 8 p.m.",
      },
      {
        title: "Th: ",
        content: "7 a.m. to 8 p.m.",
      },
      {
        title: "F: ",
        content: "7 a.m. to 8 p.m.",
      },
      {
        title: "S: ",
        content: "7 a.m. to 1 p.m.",
      },
    ],
    followUs: "Follow us",
    facebookAlt: "Facebook Logo",
    instagramAlt: "Instagram Logo",
    youtubeAlt: "Youtube Logo",
    prelibAlt: "Prélib Logo",
  },
  forgottenPassword: {
    header: "Password forgotten",
    instruction:
      "Enter your e-mail address below and we will send you instructions for resetting your password.",
    rhetoricalQuestion: "Have you forgotten your password\u00A0?",
    emailLabel: "Your email",
    invalidEmailError: "Invalid E-mail address",
    sendButtonText: "Send",
    successMessage: "THANK YOU",
  },
  homePage: {
    topMenu: {
      completeScreeningButtonText: "Confirm the appointment",
      disconnectButtonText: "Exit",
      greetingsText: "Hello",
      makeScreeningButtonText: "Conduct a screening",
      updateProfile: "Update my profile",
      myDirectoryDecorator: "MY FILE",
    },
    logo: {
      myDirectoryDecorator: "MY FILE",
    },
    subheaderTab: {
      screeningTitle: "Carry out a screening",
      myAppointmentTitle: "My appointements",
    },
    tooltipTab: {
      screening: "You already have an appointment",
    },
  },
  infoConfirmationSection: {
    birthDateLabel: "Select your birth date!",
    birthDateConfirmation: "Can you confirm that this is your birth date?",
    header: "Personal information",
    completeButtonText: "Fill out",
    confirmInfo: "Confirm info",
  },
  login: {
    alreadyHasAccountLink: "I already have an account",
    createAccountLink: "Create an account for me",
    emailConfirmationMessage: "You now need to confirm your e-mail address",
    emailLabel: "E-mail address",
    firstNameLabel: "First name",
    firstNameWarning: "As appears on RAMQ health insurance card",
    forgottenPasswordLink: "Password forgotten",
    invalidEmailError: "Invalid E-mail address",
    invalidPasswordError: "Invalid password",
    invalidPhoneNumber: "Phone number not valid",
    loginHeader: "My file",
    lastNameLabel: "Last name",
    lastNameWarning: "As appears on RAMQ health insurance card",
    loginButtonText: "Connect",
    consentMessage:
      'I have read and accept the <a href="https://prelib.com/terms" rel="noopener noreferrer" target="_blank">general terms of use </a> as well as <a href="https://prelib.com/confidentiality" rel="noopener noreferrer" target="_blank"> the privacy policy </a> of the center.',
    passwordErrors: {
      maxLength: "The password must contain no more than 128 characters.",
      minLength: "The password must contain at least 10 characters.",
      needLowercase: "The password must contain one lower-case letter.",
      needNumber: "The password must contain a digit.",
      needSpecialSymbol: "The password must contain a symbol.",
      needUppercase: "The password must contain one capital letter.",
    },
    passwordWarning:
      "This password is strictly confidential and must not be disclosed to third parties.",
    passwordLabel: "Password",
    tfaLabel: "2-Step Verification",
    tfaContext:
      "For the security of your data, your account is protected by 2-Step Authentication. When logging in, you will have to confirm your identity using a code. <b>How would you like to receive this code?</b>",
    byEmail: "By email",
    bySms: "By SMS",
    phoneNumberLabel: "Phone number",
    phoneNumberPlaceholder: "(xxx) xxx-xxxx",
    phoneNumberError: "Invalid phone number",
    requiredConsentError: "Consent required",
    requiredFieldError: "Required field",
    signUpButtonText: "I am registering",
    signUpHeader: "Create my Prélib file",
    resendConfirmationEmail: "Resend the confirmation email to ",
    confirmationResent: "A confirmation email was sent to ",
    accountCreated: "Your file was created",
    resend: "Resend confirmation email",
    formErrors: "There are errors in the form",
  },
  notification: {
    bookedAppointment: "A confirmation e-mail has been sent to you.",
    canceledAppointment: "Appointment cancelled!",
    satisfactionQuestionnaireSent: "Satisfaction questionnaire sent.",
    rescheduledAppointment: "Appointment moved!",
    scheduledPhoneAppointment: "Phone appointment scheduled!",
    appointmentUpdated: "Your appointment was updated",
    retakeRejectedAppointment: "Rescheduling of the appointment rejected!",
    unexpectedError: "An error has occurred!",
  },
  paymentSection: {
    cancelButton: "Cancel",
    cardExpirationDate: "Expiry date (MM/YY)",
    cardHolderAddress: "Address",
    cardHolderCity: "City",
    cardHolderName: "Cardholder name",
    cardHolderZipCode: "Postal code",
    cardNumber: "Card number",
    cardSecurityCode: "Security code",
    chargeExplanation:
      "By paying these fees, you will gain access to personalized recommendations and be able to book an appointment.",
    header: "Payment",
    noRefundWarning:
      "The non-refundable amount of $\u00A040.50 will be billed to you for the electronic medical consultation.",
    payButtonText: "Pay",
    privacyMessage:
      "Your data is protected by the SSL standard Internet security protocol. The data is encrypted and exchanged with an HTTPS server. We respect your confidentiality. We provide your name, address and email address only to the merchant.",
    applyCouponLabel: "Apply a coupon",
    applyCouponButton: "Apply",
    couponDiscountLabel: "Discount",
  },
  profileSection: {
    addressLabel: "Address",
    addressPlaceHolder: "Address",
    birthDateLabel: "Date of birth",
    bornGender: "Gender at birth",
    cancelTextButton: "Cancel",
    cardBillingAddressLabel: "Billing address",
    cardExpirationLabel: "Expiry",
    cardHolderNameLabel: "Name on card",
    cityPlaceHolder: "City",
    emailRemindersDisabled: "Unsubscribe follow-up",
    false: "No",
    firstNameLabel: "First name",
    generalHealthHeader: "Overall health",
    jobLabel: "Occupation",
    lastNameLabel: "Name",
    modifyLabel: "Change",
    modifyTextButton: "Change",
    paymentMethodHeader: "Payment method",
    personalInformationHeader: "Personal information",
    ramqNumberLabel: "RAMQ number",
    ramqNumberError: "Invalid RAMQ number",
    phoneNumberLabel: "Phone number",
    phoneNumberError: "Invalid phone number",
    selfDefinedGender: "Sex",
    sexualHealthHeader: "Sexual health",
    choose: "Choose",
    true: "Yes",
    zipCodePlaceHolder: "Postal code",
    followUpEmails: "Follow-up emails",
    unsubscribe: "Unsubscribe",
    subscribe: "Subscribe",
    confirmPhoneNumber: "Confirm my phone number",
    tfaPreference: "2-Step authentication preference",
    phoneNumberMustBeConfirmed:
      "You must confirm your phone number in order to use SMS as your 2-Step Authentication preference.",
    cancel: "Cancel",
    save: "Save",
  },
  phoneConfirmationModal: {
    title: "Phone number confirmation",
    sentTo: (phone) => `A confirmation code has been sent to ${phone} via SMS.`,
    instructions: "Enter it in the field below to confirm your phone number.",
    notReceived: "Haven't received a code?",
    resend: "Click here to receive another one.",
    wrongPhone: "Not your phone number?",
    changePhone: "Click here to go to your profile.",
    placeholder: "6-digit code",
    submit: "Confirm",
    invalid: "Invalid code",
    successMessage: "Your phone number is confirmed",
  },
  questionnaireComponent: {
    blockText: "or press on <strong>Enter</strong>",
    incompleteQuestionnaireError: "Invalid form",
    noRamqButton: "I am not registered to the RAMQ",
    completeBtn: "Complete",
    okBtn: "OK",
    yearLbl: "Year",
    phoneNumber: "Phone number",
    requiredFieldError: "Required field",
    invalidFormError: "There is/are invalid field(s) in the form",
    selectRequiredFieldError: "You must choose an answer",
    ramqNumberInvalidError: "Invalid RAMQ number",
    phoneInvalidError: "Invalid phone number",
    underFifteenError: "Prélib aims its services at everyone aged 15 and older",
  },
  questionnaireSection: {
    completeButtonText: "Fill out",
    header: "Form regarding overall health",
    takeQuestionnaire: "Take questionnaire",
    modal: {
      consentButtonText: "I hereby consent",
      consentHeader: "Consent",
      consentFirstP:
        "By clicking on the “Accept” button hereafter, I hereby consent to Prélib inc. (“Prélib”) and its physicians conducting one or more remote consultations as part of an asymptomatic screening process for sexual health via an electronic standardized questionnaire. Teleconsultation is available only for people physically located in Québec. By clicking the “Accept” button, I certify that I’m currently in Québec.",
      consentSecondP:
        "I hereby understand and acknowledge (i) that Prélib has at its disposal all possible technological means to reduce the risks of breach of confidentiality and/or loss of image definition during electronic transmission of this confidential information, (ii) that there are risks and limits related to the use of telemedicine and this Web site, including any technological limits associated with remote consultation and the risks inherent in data processing, (iii) that I may not use this Web site for a medical emergency, and (iv) that Prélib may collect, use and share my personal (and medical) information in accordance with the Confidentiality Policy published on this Web site",
      consentThirdP:
        "I hereby authorize the opening of an electronic patient file, in which the information transmitted during this or these consultation(s) will be deposited while ensuring the confidentiality of this personal information.",
      consentFourthP:
        "I agree to retain the services of Prélib and I consent to the remote medical consultation provided via Prélib's web site.",
      continueButtonText: "Continue",
      recommendationHeader: "Recommendations",
      recommendationSubHeader:
        "Prélib strongly recommends you consult a physician in the following situations",
      recommendationFirstP: "STBBI symptoms presents",
      recommendationSecondP:
        "At-risk sexual exposure in the past 72 hours, to assess the possibility of prophylaxis post-exposition (PPE)",
      recommendationThirdP: "Pregnancy",
      refuseButtonText: "I refuse",
    },
  },
  datetimePicker: {
    chooseAnHour: "Choose an hour",
    timePickerPlaceHolder: "Hour",
  },
  recommendationsSection: {
    restartQuestionnaire: "Redo the questionnaire",
    choseDateButtonText: "Select the date",
    updateTestSelection: "Update my selection of tests",
    customRecommendationsPresentation:
      "Based on your questionnaire responses, here is our personalized advice:",
    condomRecommendation:
      "The use of a condom during any sexual relation significantly reduces your chances of contracting an STI.",
    drugSharingRecommendation:
      "Remember, do not share or reuse injection or inhalation materials when you consume drugs.",
    extraCharges: "*Note: test fees are payable up front.",
    header: "Recommendations",
    importantNewEvaluation:
      "You have 45 days to book an appointment, after which a new medical evaluation will be required.",
    importantCancelTest:
      "At your appointment, you can still choose to remove an unwanted laboratory analysis.",
    importantDisabled:
      "If you have impaired mobility, e-mail us prior to your appointment, at info@prelib.com, for the address where you can gain access to the center.",
    importantFee:
      "At your appointment, payment to cover the courier fees ($5 to $15, if applicable) or the Dynacare private laboratory fees (if applicable) will be required.",
    importantHeader: "IMPORTANT",
    importantSymptoms:
      "If, prior to your appointment, you develop noticeable symptoms, it is recommended that you immediately consult a physician in person.",
    medicalTestsPresentation:
      "Based on your questionnaire responses, we have preselected the appropriate tests for you. That said, you can change this selection if you wish. Your recommended tests are:",
    minimalTestsCountError: "You must select at least one test.",
    ppeLink: "post-exposure prophylaxis (PEP).",
    ppeRecommendation:
      "Because you had at-risk sexual relations in the past 72 hours, Prélib strongly recommends that you consult a clinic to evaluate whether you should be prescribed",
    prepLink: "pre-exposure prophylaxis (PrEP).",
    prepRecommendation:
      "Because your sexual behaviours present a considerable risk for contracting HIV, consult a specialized clinic for information about",
    prepRecommendation2: "",
    retakeRecommendationExplanation:
      "Repeating a screening for the diseases listed above will cover the window period, which is the maximum period before the infection can be detected in the blood.",
    retakeRecommendationHeader:
      "Since recommended wait times between potential exposure and the taking of samples to ensure accurate results vary according to the type of infection, it may be appropriate to redo a screening according to the different window periods.",
    retakeRecommendationHIV: "HIV: control to be carried out starting",
    retakeRecommendationHepatiteC:
      "Hepatitis C: control to be carried out starting",
    retakeRecommendationSyphilis:
      "Syphilis: control to be carried out starting",
    vaxRecommendation:
      "Your vaccination profile appears to be out of date. Ask your family doctor or a specialized clinic for information. It's simple, and often free of charge!",
    combinedOralNaatTest: "Chlamydia/Gonorrhea throat (NAAT)",
    itssSlug: "stbbi",
    appointmentUpdated: "Your appointment has been updated",
  },
  sectionSelection: {
    appointmentsSection: {
      canceledAppointments: "My cancelled appointments",
      header: "My appointments",
      pastAppointments: "My previous appointments",
      upcomingAppointments: "My upcoming appointments",
      missedAppointments: "My missed appointments",
    },
    makeScreeningSection: {
      date: "Appointment",
      header: "Carry out a screening",
      information: "Information",
      medicalEvaluation: "Medical evaluation",
      payment: "Payment",
      recommendations: "Recommendations",
      expired: "Expired questionnaire",
      appointmentCreated: "Your appointment has been scheduled",
    },
    myProfileSection: {
      generalHealth: "Overall health",
      header: "My profile",
      personalInformation: "Personal information",
      sexualHealth: "Sexual health",
    },
    myResultsSection: {
      header: "My results",
      tooltipMessage: "You have no results",
    },
    welcomeTextBasic: "Welcome to the Prélib platform",
    welcomeTextInfo: "Your last screening was on",
    welcomeTextReminder: "You have an appointment on",
  },
  resultsSection: {
    invalidResultLabel: "Invalid",
    undisclosedResultLabel: "Pending",
    toRetakeResultLabel: "To be retaken",
    negativeResultLabel: "Negative",
    positiveResultLabel: "Positive",
    notDoneResultLabel: "Not done",
    cancelled: "Cancelled",
    recommendations: "Recommendations:",
    recommendationsWhenCancel:
      "Even if the appointment is cancelled, those recommendations are still valid:",
    recommendationsWhenNoResult:
      "Until then, keep in mind those recommendations:",
    recommendationsWhenNotDone:
      "Even if the appointment is not done yet, those recommendations are still valid:",
    resultsNotReadyLine1:
      "Your results have not arrived yet, but it won't be much longer.",
    resultsNotReadyLine2:
      "You will receive a message when they become available.",
    giveUsYourFeedback: "You want to give us your feedback?",
    thankYouForFeedback: "Thank you for your feedback",
    rowHeader: (date) => `${date} appointment`,
    positiveInfo: {
      row1_1: "One of your test results is positive. ",
      row1_2:
        "To get treatment, Prélib works in collaboration with Quorum Medical Clinic, where you may quickly get an appointment, if you wish. You can make an appointment online by visiting ",
      row1_3: "www.cliniquequorum.com/portail-patient/",
      row1_4: " via telephone by calling ",
      row1_5: "514 360-0614",
      row1_6:
        " or without an appointment by coming to the clinic during opening hours.",
      appointmentLink: "http://cliniquequorum.com/portail-patient/",
      appointmentPhonenumber: "tel:+15143600614",

      row3_1:
        "It is very important that you abstain from all sexual relations until your appointment.",
      FAQLink: "https://www.prelib.com/fr#faqs",
    },
    pendingInfo: {
      header: "Results pending:",
      explanation:
        "A sample is “pending” when it has yet to undergo laboratory analysis. This turnaround time is normal, and the results will be sent to you before long (expect up to seven additional days).",
    },
    retakeInfo: {
      row1_1: "One of your samples could not be interpreted by the lab.",
      row1_2: " This may be a laboratory error, but it is not a concern.",
      row2_1:
        "Prélib will retake this sample free of charge (within 45 days) when you make an appointment at the centre.",
      retakeButton: "Reschedule appointment",
      rejectRetakeButton: "I do not wish to reschedule an appointment",
    },
    schedulePhoneAppointment: {
      title:
        "You have been invited to schedule a phone appointment. Choose a date.",
      scheduleButton: "Schedule appointment",
    },
  },
  resetPassword: {
    changeButtonText: "Change",
    confirmationLabel: "Confirm password",
    header: "New password",
    invalidPasswordError: "Invalid password",
    passwordDontMatchError: "Passwords don't match",
    invalidTokenError: "The token is not valid!",
    passwordLabel: "Password",
    successMessage: "Your password has been reset!",
  },
  tfaInput: {
    title: "2-Step Authentication",
    sentTo: (sentBy, sentTo) =>
      `Your account is protected by a 2-Step Authentication. An authentication 6-digit code has been sent by ${sentBy} to ${sentTo}.`,
    resentCode: (sentBy, sentTo) =>
      `An authentication 6-digits code has been resent by ${sentBy} to ${sentTo}.`,
    checkboxLabel:
      "Don't ask me for the code again for 30 days when I use this computer.",
    fieldLabel: "Enter 6-digit code :",
    codeError: "Invalid code",
    verifyButton: "Verify",
    resendCode: "Resend a 6-digit authentication code",
  },
  tfaSettings: {
    title: "2 step-verification",
    subtitle:
      "For the security of your data, your account will now be protected by 2-step authentication. When logging in, you will have to confirm your identity using a code. How do you want to receive this code?",
    confirmButton: "Continue",
    bySms: "By SMS",
    byEmail: "By email",
    phoneConfirmationButton: "Confirm phone number",
    phoneNumberLabel: "Phone number",
    phoneNumberPlaceholder: "Format of the number : (xxx) xxx-xxx",
    phoneNumberError: "Invalid phone number",
  },
  select: {
    startTyping: "Start typing",
    keepTyping: "Keep typing",
    noResults: "No results",
  },
  months: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  humanizeDateFormatter: {
    unknownDate: "No time slot are available at the moment",
    nextAvailableTime: "The next available time slot is",
  },
};
