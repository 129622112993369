import MedicalTestTranslation from "./medical-test-translation";

export default interface MedicalTest {
  id: number;
  name: string;
  medicalTestTranslations: MedicalTestTranslation[];
}

// Utils methods

export const getLocaleName = (
  medicalTest: MedicalTest,
  locale: string
): string =>
  medicalTest.medicalTestTranslations.find(({ lang }) => lang === locale)
    ?.value ?? medicalTest.medicalTestTranslations[0].value;
