import { AppointmentCard, Button, Modal } from "../../../components";
import {
  CancelAppointmentModal,
  EditAppointmentModal,
  FeedbackModal,
  RejectPhoneAppointmentModal,
  ScheduleAppointmentModal,
  SchedulePhoneAppointmentModal,
} from "../../../modals";
import React, { useEffect, useState } from "react";
import useLanguage from "../../../hooks/language";
import Axios from "axios";
import ENDPOINTS from "../../../utils/endpoints";
import Appointment, {
  getPhoneAppointmentToSchedule,
} from "../../../models/appointment";
import PhoneAppointment from "../../../models/phone-appointment";
import ROUTES from "../../../utils/routes";
import { DateTime } from "luxon";
import styles from "./my-appointments.module.css";
import Tabs, { Tab } from "../../../components/tabs";
import { useHistory } from "react-router-dom";
import useAuthUser from "../../../hooks/auth-user";
import {
  appointmentWithSatisfactionQuestionnairePrompt,
  isAppointment,
  isUpcoming,
  sortAppointments,
} from "../../../utils/appointment-helpers";
import { Loader } from "react-feather";
type AppointmentAction =
  | "edit"
  | "reject"
  | "reschedule"
  | "schedule-retake"
  | "schedule-phone-appointment"
  | "schedule-follow-up-appointment"
  | "feedback";

const MyAppointments: React.FunctionComponent = () => {
  const { translations, language } = useLanguage();
  const [authUser, setAuthUser] = useAuthUser();
  const { push } = useHistory();
  const t = translations.appointmentsSection;

  // States

  const [modalAppointment, setModalAppointment] = useState<Appointment>();
  const [
    modalPhoneAppointment,
    setModalPhoneAppointment,
  ] = useState<PhoneAppointment>();

  const [feedbackModalVisible, setFeedbackModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [rejectModalVisible, setRejectModalVisible] = useState(false);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [
    scheduleAppointmentModalVisible,
    setScheduleAppointmentModalVisible,
  ] = useState(false);
  const [
    schedulePhoneAppointmentModalVisible,
    setSchedulePhoneAppointmentModalVisible,
  ] = useState(false);

  // Effects
  useEffect(() => {
    if (!authUser) return;

    Axios.put(ENDPOINTS.APPOINTMENTS, {});

    const appointmentNeedImmediateFeedBack = appointmentWithSatisfactionQuestionnairePrompt(
      authUser.appointments
    );
    // If user has a satisfaction questionnaire to fill, he/she shall b prompted
    if (appointmentNeedImmediateFeedBack)
      onClickAppointmentButton(appointmentNeedImmediateFeedBack, "feedback");
  }, [authUser]);
  // Handlers

  const onClickAppointmentButton = (
    appointment: Appointment | PhoneAppointment,
    action: AppointmentAction
  ) => {
    if (isAppointment(appointment)) {
      setModalAppointment(appointment);

      if (action === "schedule-follow-up-appointment") {
        setModalPhoneAppointment(getPhoneAppointmentToSchedule(appointment));
      }
    } else {
      setModalPhoneAppointment(appointment);
    }

    switch (action) {
      case "edit":
        setEditModalVisible(true);
        break;
      case "reject":
        setRejectModalVisible(true);
        break;
      case "reschedule":
        // TODO: Implement fee payment when rescheduling
        setScheduleAppointmentModalVisible(true);
        break;
      case "schedule-retake":
        setScheduleAppointmentModalVisible(true);
        break;
      case "schedule-phone-appointment":
        setSchedulePhoneAppointmentModalVisible(true);
        break;
      case "schedule-follow-up-appointment":
        setSchedulePhoneAppointmentModalVisible(true);
        break;
      case "feedback":
        setFeedbackModalVisible(true);
        break;
    }
  };

  const onAppointmentChange = async () => {
    const { data } = await Axios.get(ENDPOINTS.ME);
    setAuthUser(data);

    setModalAppointment(undefined);
    setModalPhoneAppointment(undefined);

    setEditModalVisible(false);
    setCancelModalVisible(false);
    setRejectModalVisible(false);
    setScheduleAppointmentModalVisible(false);
    setSchedulePhoneAppointmentModalVisible(false);
    setFeedbackModalVisible(false);
  };

  const mapAppointmentToCard = (
    appointment: Appointment | PhoneAppointment
  ) => (
    <AppointmentCard
      key={appointment.id}
      appointment={appointment}
      onEditClick={() => onClickAppointmentButton(appointment, "edit")}
      onRejectClick={() => onClickAppointmentButton(appointment, "reject")}
      onRescheduleClick={() =>
        onClickAppointmentButton(appointment, "reschedule")
      }
      onScheduleRetakeClick={() =>
        onClickAppointmentButton(appointment, "schedule-retake")
      }
      onSchedulePhoneAppointmentClick={() =>
        onClickAppointmentButton(appointment, "schedule-follow-up-appointment")
      }
      onChooseDateClick={() =>
        onClickAppointmentButton(appointment, "schedule-phone-appointment")
      }
      onFeedbackClick={() => onClickAppointmentButton(appointment, "feedback")}
      onRetakeQuestionnaireClick={() => push(ROUTES.SCREENING)}
    />
  );

  // Rendering
  const isLoading = authUser === null || authUser === undefined;
  const appointments = (authUser
    ? [...authUser.phoneAppointments, ...authUser.appointments]
    : []
  ).sort(sortAppointments);

  const upcomingAppointments = appointments.filter((app) => isUpcoming(app));
  const pastAppointments = appointments.filter((app) => !isUpcoming(app));

  const loadingElement = (
    <p className={styles.loader}>
      <Loader className="spinorama" />
      {t.loadingInfo}
    </p>
  );
  // No appointment
  const hasNoAppointment =
    upcomingAppointments.length === 0 && pastAppointments.length === 0;

  const noAppointmentElement = hasNoAppointment ? (
    <div className={styles.noAppointmentHolder}>
      <h3>{t.noAppointment}</h3>
      <div className="dual-button-holder">
        <Button onClick={() => push(ROUTES.SCREENING)} type="secondary">
          {t.clickHereToSchedule}
        </Button>
      </div>
    </div>
  ) : null;

  const upcomingApointmentListElement =
    upcomingAppointments.length > 0 ? (
      <div>
        <div className="container">
          {upcomingAppointments.map(mapAppointmentToCard)}
        </div>
      </div>
    ) : null;
  const pastApointmentListElement =
    pastAppointments.length > 0 ? (
      <div>
        <div className="container">
          {pastAppointments.map(mapAppointmentToCard)}
        </div>
      </div>
    ) : null;

  // Local date time string
  const modalAppointmentDate = modalAppointment
    ? DateTime.fromISO(modalAppointment.datetime)
    : null;
  const modalAppointmentDateString = modalAppointmentDate
    ? modalAppointmentDate.setLocale(language).toLocaleString(DateTime.DATE_MED)
    : null;

  const tabs: Tab[] = [];
  if (!isLoading) {
    if (pastApointmentListElement)
      tabs.push({
        title: t.pastAppointments,
        content: pastApointmentListElement,
      });
    if (upcomingApointmentListElement) {
      tabs.push({
        title: t.upcomingAppointments,
        content: upcomingApointmentListElement,
      });
    }
  }

  return (
    <div>
      {/* Edit appointment modal */}
      <Modal
        title={
          modalAppointmentDateString
            ? t.cancellationModal.header(modalAppointmentDateString)
            : null
        }
        visible={editModalVisible}
        onVisibilityChange={setEditModalVisible}
      >
        <EditAppointmentModal
          onRescheduleClick={() => setScheduleAppointmentModalVisible(true)}
          onCancelClick={() => setCancelModalVisible(true)}
        />
      </Modal>

      {/* Cancel appointment modal */}
      <Modal
        title={t.cancellationConfirmModal.title}
        visible={cancelModalVisible}
        onVisibilityChange={setCancelModalVisible}
      >
        <CancelAppointmentModal
          onClose={() => setCancelModalVisible(false)}
          onConfirm={onAppointmentChange}
          appointment={modalAppointment}
        />
      </Modal>

      {/* Reject phone appointment modal */}
      <Modal
        title={t.cancellationConfirmModal.phoneTitle}
        visible={rejectModalVisible}
        onVisibilityChange={setRejectModalVisible}
      >
        <RejectPhoneAppointmentModal
          onClose={() => setRejectModalVisible(false)}
          onConfirm={onAppointmentChange}
          appointment={modalPhoneAppointment}
        />
      </Modal>

      {/* Schedule appointment modal */}
      <Modal
        title={t.rescheduleAppointmentModal.title}
        visible={scheduleAppointmentModalVisible}
        onVisibilityChange={setScheduleAppointmentModalVisible}
      >
        <ScheduleAppointmentModal
          isVisible={scheduleAppointmentModalVisible}
          onClose={() => setScheduleAppointmentModalVisible(false)}
          onConfirm={onAppointmentChange}
          appointment={modalAppointment}
        />
      </Modal>

      {/* Schedule phone appointment modal */}
      <Modal
        title={t.rescheduleAppointmentModal.title}
        visible={schedulePhoneAppointmentModalVisible}
        onVisibilityChange={setSchedulePhoneAppointmentModalVisible}
      >
        <SchedulePhoneAppointmentModal
          onClose={() => setSchedulePhoneAppointmentModalVisible(false)}
          onConfirm={onAppointmentChange}
          appointment={modalPhoneAppointment}
        />
      </Modal>

      {/* Feedback modal */}
      <Modal
        title={t.feedbackModal.title}
        subtitle={t.feedbackModal.letUsKnowHowItWent}
        visible={feedbackModalVisible}
        onVisibilityChange={setFeedbackModalVisible}
      >
        <FeedbackModal
          appointment={modalAppointment}
          onClose={() => setFeedbackModalVisible(false)}
        />
      </Modal>
      {isLoading && loadingElement}
      {!isLoading && noAppointmentElement}
      {tabs.length > 0 && <Tabs tabs={tabs} />}
    </div>
  );
};

export default MyAppointments;
