import { DateTime } from "luxon";

export default interface Clinic {
  id: number;
  code: number;
  timezone: string;
  name: string;
  city: string;
  address: string;
  nextAvailableTime: string;
}

export const humanizeNextAvailableDate = (
  { nextAvailableTime, timezone }: Clinic,
  locale: string
): string | undefined => {
  if (!nextAvailableTime) return undefined;

  const date = DateTime.fromISO(nextAvailableTime, {
    zone: timezone,
  });

  const relativeDayString = date.toRelativeCalendar({
    unit: "days",
    locale: locale,
  });
  const timeSlotString = date.toLocaleString({
    timeStyle: "short",
    locale: locale,
  });

  return `${relativeDayString} ${timeSlotString}`;
};
