import React from "react";
import Question, { extractQuestionError } from "../../../models/question";
import useLanguage from "../../../hooks/language";

import styles from "./tracker.module.css";

interface TrackerProps {
  questions: Question[];
  focusedIndex: number;
  onClickQuestion: (index: number) => void;
  showErrors?: boolean;
}

const Tracker: React.FunctionComponent<TrackerProps> = ({
  focusedIndex = 0,
  showErrors = false,
  questions,
  onClickQuestion,
}: TrackerProps) => {
  const { translations } = useLanguage();

  // TODO: Make sticky translation smoother
  // TODO: Create effect to detect change in focus index to automatically scroll container to index

  // Rendering

  const renderQuestionIndex = (question: Question, index: number) => {
    const error = extractQuestionError(question, translations);

    const shouldShowError = error !== undefined && showErrors;
    return (
      <div
        className={`${styles.indexHolder} ${
          index === focusedIndex ? styles.active : ""
        } ${index < focusedIndex ? styles.done : ""} ${
          shouldShowError ? styles.hasError : ""
        }`}
      >
        <span />
        <div onClick={() => onClickQuestion(index)}>{question.index}</div>
      </div>
    );
  };

  const renderContainer = () => (
    <div className={`${styles.trackerContainer} container`}>
      <div className={styles.indexList}>
        {questions.map(renderQuestionIndex)}
      </div>
    </div>
  );

  return (
    <div>
      <div className={`${styles.tracker} ${styles.fixed}`}>
        {renderContainer()}
      </div>
      <div className={styles.tracker}>{renderContainer()}</div>
    </div>
  );
};

export default Tracker;
