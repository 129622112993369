import React, { useState, useEffect } from "react";
import styles from "./tabs.module.css";
export interface Tab {
  content: React.ReactNode;
  title: string;
}

interface TabsInterface {
  tabs: Tab[];
  className?: string;
  currentIndex?: number;
  onChange?: (tab: Tab, index: number) => void;
}

const Tabs: React.FunctionComponent<TabsInterface> = ({
  tabs,
  className,
  currentIndex = 0,
  onChange,
}: TabsInterface) => {
  // States

  const [_currentIndex, setCurrentIndex] = useState(currentIndex);

  // Effects

  useEffect(() => {
    setCurrentIndex(currentIndex);
  }, [currentIndex]);

  useEffect(() => {
    if (onChange) onChange(tabs[_currentIndex], _currentIndex);
  }, [_currentIndex]);

  useEffect(() => {
    if (tabs.length <= _currentIndex) setCurrentIndex(0);
  }, [tabs]);

  // Rendering

  let rootClass = styles.holder;
  if (className) rootClass += ` ${className}`;

  const tabContents = tabs.map(({ content }: Tab, index) => (
    <div key={index}>{content}</div>
  ));

  return (
    <div className={rootClass}>
      <div className={`${styles.containerOverride} container`}>
        <div className={styles.header}>
          {tabs.map(({ title }, index) => (
            <button
              key={index}
              onClick={() => setCurrentIndex(index)}
              className={_currentIndex === index ? styles.active : undefined}
            >
              <h4>{title} </h4>
            </button>
          ))}
        </div>
      </div>
      <div>{tabContents[_currentIndex]}</div>
    </div>
  );
};

export default Tabs;
