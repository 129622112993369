import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useLocation, useHistory, Redirect } from "react-router-dom";
import { Loader } from "react-feather";
import { Button } from "../../components";
import useLanguage from "../../hooks/language";
import ROUTES from "../../utils/routes";
import ENDPOINTS from "../../utils/endpoints";
import { Footer, Header } from "../../components";

// TODO: Test unsubscription

const UnsubscribePage: React.FunctionComponent = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const token = searchParams.get("token") || undefined;

  const { push } = useHistory();

  const { translations } = useLanguage();
  const t = translations.unsubscribeConfirmation;

  // State

  const [success, setSuccess] = useState<null | boolean>(null);

  // Effects

  useEffect(() => {
    if (success === null) unsubscribe();
  }, [search]);

  // Network

  const unsubscribe = async () => {
    try {
      await Axios.put(ENDPOINTS.UNSUBSCRIBE(token || ""));
      setSuccess(true);
    } catch (e) {
      setSuccess(false);
    }
  };

  // Rendering

  if (!token) return <Redirect to={ROUTES.HOME} />;
  return (
    <div className="page">
      <Header />
      <div className="preauth-container">
        <h2>{t.header}</h2>
        {success === null && <Loader className="spinorama" />}
        {success === false && (
          <p
            className="has-error"
            dangerouslySetInnerHTML={{ __html: t.invalidTokenError }}
          />
        )}
        {success === true && (
          <div>
            <p dangerouslySetInnerHTML={{ __html: t.successMessage }} />
            <Button type="secondary" onClick={() => push(ROUTES.HOME)}>
              {t.goToHomeButton}
            </Button>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default UnsubscribePage;
