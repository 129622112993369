import React from "react";
import { ArrowRight, Check } from "react-feather";

import styles from "./breadcrums.module.css";

interface BreadcrumsProps {
  currentIndex?: number;
  maxIndex?: number;
  minIndex?: number;
  crumTitles: string[];
  onClickIndex?: (index: number) => void;
}

const Breadcrums: React.FunctionComponent<BreadcrumsProps> = ({
  currentIndex = 0,
  maxIndex = 0,
  minIndex = 0,
  crumTitles,
  onClickIndex,
}: BreadcrumsProps) => {
  // Rendering

  const renderCrum = (title: string, index: number) => (
    <button
      className={`${styles.crum} ${
        currentIndex === index ? styles.active : ""
      }`}
      disabled={index > maxIndex || index < minIndex}
      onClick={onClickIndex ? () => onClickIndex(index) : undefined}
    >
      {title}
      {index < maxIndex ? <Check /> : <ArrowRight />}
    </button>
  );

  return (
    <div className={styles.breadcrums}>
      <div className="container">{crumTitles.map(renderCrum)}</div>
    </div>
  );
};

export default Breadcrums;
