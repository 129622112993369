export default {
  appointmentsSection: {
    loadingInfo: "Chargement de tes informations",
    upcomingAppointments: "Tes rendez-vous à venir",
    pastAppointments: "Tes rendez-vous passés",
    noAppointment: "Tu n'as pas encore de rendez-vous",
    clickHereToSchedule: "Cliquez ici pour faire un dépistage",
    appointmentCard: {
      cancelButtonText: "Annuler",
      editButtonText: "Modifier",
      rescheduleButton: "Reporter",
      retakeQuestionnaire: "Recommencer le questionnaire",
      cancellationIndicator: "ANNULÉ",
      staffCancellationIndicator: "DOIT ÊTRE REPORTÉ",
      consultButtonText: "Consulter",
      dateIndicator: (date) => `Rendez-vous de dépistage du ${date} `,
      medicalTestsIndicator: "Examens à passer",
      resultsIndicator: "Tes résultats",
      missedMessage:
        "Tu as manqué ton rendez-vous mais il est encore temps de le modifier.",
      resultsNotReadyLine1: "Tes résultats sont toujours en attente,",
      resultsNotReadyLine2: "mais cela ne devrait pas tarder.",
      missedAppointmentMessage:
        "Tu as manqué ton rendez-vous et ton questionnaire a expiré. Pour prendre rendez-vous, complète le questionnaire médical à nouveau.",
    },
    phoneAppointmentCard: {
      chooseButtonText: "Choisir",
      editButtonText: "Changer",
      consultButtonText: "Consulter",
      rejectButtonText: "Refuser",
      title: "Rendez-vous téléphonique",
      independantTitle: "Rendez-vous téléphonique",
      rejectedIndicator: "REFUSÉ",
      dateIndicator: (date) => `Rendez-vous téléphonique du ${date}`,
      independantDateIndicator: (date) => `Rendez-vous téléphonique du ${date}`,
      descriptionLine1: (assAppDate) =>
        `Rendez-vous téléphonique en lien avec le rendez-vous de dépistage du ${assAppDate}.`,
      descriptionLine2:
        "Un membre du personnel te contactera durant la plage horaire réservée au rendez-vous.",
      missedMessage:
        "Il semble que tu aies manqué l’appel téléphonique prévu. Tu peux le reprendre dès maintenant.",
      chooseDate:
        "Tu as été invité à un rendez-vous téléphonique. Choisis une date.",
    },
    cancellationModal: {
      cancellationButtonText: "Je désire annuler mon rendez-vous*",
      confirmButtonText: "Confirmer",
      exitButtonText: "Annuler",
      header: (date) => `Modifier ton rendez-vous du ${date}`,
      keepButtonText: "Non, je désire conserver mon rendez-vous",
      payButton: "Payer",
      rescheduleButtonText: "J'aimerais choisir une autre date",
      feesMightApply: "*Certains frais peuvent s'appliquer",
      faqUrl: "https://www.prelib.com/fr?faq=14#faqs",
      appointmentCanceled: "Le rendez-vous a été annulé",
      phoneAppointmentRejected: "Le rendez-vous a été rejeté",
    },
    cancellationConfirmModal: {
      title: "L'annulation du rendez-vous sera irréversible!",
      phoneTitle: "L'annulation du rendez-vous téléphonique sera irréversible!",
      content:
        "En cas d’annulation de ton rendez-vous, les frais liés à la consultation médicale électronique ne pourront être remboursés.",
    },
    creditInfoModal: {
      cardBillingAddressLabel: "Adresse de facturation",
      cardExpirationLabel: "Expire",
      cardHolderNameLabel: "Nom sur la carte",
      content:
        "Confirme tes informations de carte de crédit pour le paiement des\u00A0",
      modifyButton: "Modifier",
      link: "frais administratifs de 15 $ (taxes en sus)",
      faqUrl: "https://www.prelib.com/fr?faq=14#faqs",
      payButton: "Payer",
    },
    gatherCreditInfoModal: {
      content:
        "Entre tes informations de carte de crédit pour le paiement des\u00A0",
      link: "frais administratifs de 15 $ (taxes en sus)",
    },
    rescheduleAppointmentModal: {
      title: "Choisir une clinique et une date de rendez-vous",
      confirmButtonText: "Choisir",
      exitButtonText: "Annuler",
    },
    feedbackModal: {
      title: "Questionnaire de satisfaction",
      letUsKnowHowItWent: "Fais-nous part de ton expérience chez Prélib",
      satisfactionLevel:
        "Quel est ton niveau de satisfaction avec le service Prélib?",
      recommendationProbability:
        "Quelle est la probabilité que tu recommandes Prélib à ton entourage?",
      recommendationJustification:
        "Dans tes propres mots, pourquoi as-tu inscrit cette note? ",
      phoneCommunicationAgreement:
        "À des fins d'amélioration de nos services, accepterais-tu qu’un membre de notre équipe communique avec toi par téléphone?",
      yes: "Oui",
      no: "Non",
      textAreaPlaceholder: "Écrire une note ...",
      confirmButtonText: "Soumettre",
      exitButtonText: "Annuler",
    },
  },
  dateSection: {
    header: "Choisis ta clinique et ta plage horaire",
    submitButtonText: "Soumettre",
  },
  dateSelectorComponent: {
    choseDateLabel: "Choisir une date",
    choseHourLabel: "Heure",
    noAvailability: "Pas de disponibilité",
    noDateAvailability:
      "Pas de date disponible pour l'instant. Reviens demain.",
  },
  emailConfirmation: {
    goToHomeButton: "Voir mon profil",
    header: "Confirmation du courriel",
    invalidTokenError: "Le jeton est invalide&#8239;!",
    successMessage: "Ton courriel est confirmé&#8239;!",
  },
  footer: {
    unknownCity: "Montréal",
    unknownCivicAddress: "1485 rue Saint-Hubert",
    disclaimer: "@2020 Prelib Tous droits réservés",
    emailLabel: "C",
    weekendLabel: "S",
    beginningOfWeekLabel: "L - M",
    endOfWeekLabel: "J - V",
    schedule: [
      {
        title: "Lun",
        content: "7h00 à 20h00",
      },
      {
        title: "Mar",
        content: "7h00 à 20h00",
      },
      {
        title: "Me",
        content: "7h00 à 20h00",
      },
      {
        title: "Jeu",
        content: "7h00 à 20h00",
      },
      {
        title: "Ven",
        content: "7h00 à 20h00",
      },
      {
        title: "Sat",
        content: "7h00 à 13h00",
      },
    ],
    followUs: "Suis-nous",
    facebookAlt: "Logo Facebook",
    instagramAlt: "Logo Instagram",
    youtubeAlt: "Logo Youtube",
    prelibAlt: "Logo Prélib",
  },
  forgottenPassword: {
    header: "Mot de passe oublié",
    instruction:
      "Entre ton adresse courriel ci-dessous et nous t’enverrons un courriel pour le changer.",
    rhetoricalQuestion: "Tu as oublié ton mot de passe ?",
    emailLabel: "Ton courriel",
    invalidEmailError: "Courriel invalide",
    sendButtonText: "Envoyer",
    successMessage: "Un courriel t'a été envoyé.",
  },
  homePage: {
    topMenu: {
      completeScreeningButtonText: "Finaliser le rendez-vous",
      disconnectButtonText: "Quitter",
      greetingsText: "Bonjour",
      makeScreeningButtonText: "Faire un dépistage",
      updateProfile: "Modifier mon profil",
      myDirectoryDecorator: "MON DOSSIER",
    },
    logo: {
      myDirectoryDecorator: "MON DOSSIER",
    },
    subheaderTab: {
      screeningTitle: "Faire un dépistage",
      myAppointmentTitle: "Mes rendez-vous",
    },
    tooltipTab: {
      screening: "Tu as déjà un rendez-vous prévu",
    },
  },
  unsubscribeConfirmation: {
    goToHomeButton: "Voir mon profil",
    header: "Confirmation du désabonnement",
    invalidTokenError: "Le jeton est invalide&#8239;!",
    successMessage:
      "Tu n’es plus abonné.e aux courriels automatisés de Prélib. Il sera possible de t’abonner à nouveau dans ton dossier en ligne en cliquant sur le bouton « S’abonner aux courriels de suivi » dans la section Informations personnelles.",
  },
  infoConfirmationSection: {
    birthDateLabel: "Indique ta date de naissance\u202F!",
    birthDateConfirmation:
      "Ta date de naissance est donc la date ci-dessous, est-ce exact\u202F?",
    header: "Informations personnelles",
    completeButtonText: "Compléter",
    confirmInfo: "Confirmer informations",
  },
  login: {
    alreadyHasAccountLink: "J'ai déjà un compte",
    createAccountLink: "Me créer un compte",
    emailConfirmationMessage:
      "Tu dois maintenant aller confirmer ton adresse courriel",
    emailLabel: "Adresse courriel",
    firstNameLabel: "Prénom",
    firstNameWarning: "Tel que figurant sur la carte RAMQ",
    forgottenPasswordLink: "Mot de passe oublié",
    invalidEmailError: "Courriel invalide",
    invalidPasswordError: "Mot de passe invalide",
    loginHeader: "Mon dossier",
    lastNameLabel: "Nom de famille",
    lastNameWarning: "Tel que figurant sur la carte RAMQ",
    loginButtonText: "Me connecter",
    consentMessage:
      'J\'ai lu et accepte les <a href="https://prelib.com/termes" rel="noopener noreferrer" target="_blank"> termes d\'utilisation </a> et la <a href="https://prelib.com/vie-privee" rel="noopener noreferrer" target="_blank"> politique de confidentialité </a> de la clinique.',
    passwordErrors: {
      maxLength: "Le mot de passe doit contenir au maximum 128 caractères.",
      minLength: "Le mot de passe doit contenir au minimum 10 caractères.",
      needLowercase: "Le mot de passe doit contenir une lettre minuscule.",
      needNumber: "Le mot de passe doit contenir un chiffre.",
      needSpecialSymbol: "Le mot de passe doit contenir un symbole.",
      needUppercase: "Le mot de passe doit contenir une lettre majuscule.",
    },
    passwordWarning:
      "Ce mot de passe est strictement confidentiel et ne doit pas être divulgué à des tiers.",
    passwordLabel: "Mot de passe",
    tfaLabel: "Vérification en 2 étapes",
    tfaContext:
      "Pour la sécurité de tes données, ton compte est protégé par une authentification en 2 étapes. Lors de la connexion, tu devras confirmer ton identité à partir d’un code. <b>De quelle façon souhaites-tu recevoir ce code?</b>",
    byEmail: "Par courriel",
    bySms: "Par SMS",
    phoneNumberLabel: "Numéro de téléphone",
    phoneNumberPlaceholder: "(xxx) xxx-xxxx",
    phoneNumberError: "Numéro de téléphone invalide",
    requiredConsentError: "Consentement requis",
    requiredFieldError: "Champs requis",
    signUpButtonText: "Je m'inscris",
    signUpHeader: "Créer mon dossier Prélib",
    resendConfirmationEmail: "Renvoyer le courriel de confirmation à ",
    confirmationResent: "Le courriel de confirmation a été envoyé à ",
    accountCreated: "Ton dossier a été créé",
    resend: "Renvoyer le courriel de confirmation",
    formErrors: "Il y a des erreurs dans le formulaire",
  },
  notification: {
    bookedAppointment: "Un courriel de confirmation t’a été envoyé.",
    satisfactionQuestionnaireSent: "Formulaire de satisfaction envoyé.",
    canceledAppointment: "Rendez-vous annulé!",
    rescheduledAppointment: "Rendez-vous déplacé!",
    scheduledPhoneAppointment: "Rendez-vous téléphonique prévu!",
    appointmentUpdated: "Ton rendez-vous a été mis-à-jour",
    retakeRejectedAppointment: "Reprise de rendez-vous rejetée!",
    unexpectedError: "Une erreur est survenue!",
  },
  paymentSection: {
    cancelButton: "Annuler",
    cardExpirationDate: "Date d’expiration (MM/AA)",
    cardHolderAddress: "Adresse",
    cardHolderCity: "Ville",
    cardHolderName: "Nom du titulaire de la carte",
    cardHolderZipCode: "Code postal",
    cardNumber: "Numéro de la carte",
    cardSecurityCode: "Code de sécurité",
    chargeExplanation:
      "En acquittant ces frais, il sera possible d’accéder aux recommandations personnalisées ainsi qu’à la prise de rendez-vous.",
    header: "Paiement",
    noRefundWarning:
      "Une somme non remboursable de 41,50\u00A0$ te sera facturée pour la consultation médicale électronique.",
    payButtonText: "Payer",
    privacyMessage:
      "Tes données sont protégées en utilisant le protocole standard de sécurité sur Internet SSL. Ces données sont cryptées et échangées avec un serveur HTTPS. Nous respectons ta confidentialité. Nous transmettrons ton nom, adresse et courriel au marchand uniquement.",
    applyCouponLabel: "Appliquer un coupon",
    applyCouponButton: "Appliquer",
    couponDiscountLabel: "Rabais",
  },
  profileSection: {
    addressLabel: "Adresse",
    addressPlaceHolder: "Adresse",
    birthDateLabel: "Date de naissance",
    bornGender: "Sexe à la naissance",
    cancelTextButton: "Annuler",
    cardBillingAddressLabel: "Adresse de facturation",
    cardExpirationLabel: "Expire",
    cardHolderNameLabel: "Nom sur la carte",
    cityPlaceHolder: "Ville",
    emailRemindersDisabled: "Désabonner suivis",
    false: "Non",
    firstNameLabel: "Prénom",
    generalHealthHeader: "Santé Générale",
    jobLabel: "Profession",
    lastNameLabel: "Nom",
    modifyLabel: "Modifier",
    modifyTextButton: "Modifier",
    paymentMethodHeader: "Ma méthode de paiement",
    personalInformationHeader: "Informations personnelles",
    ramqNumberLabel: "Numéro RAMQ",
    ramqNumberError: "Numéro RAMQ invalide",
    phoneNumberLabel: "Numéro de téléphone",
    phoneNumberError: "Numéro de téléphone invalide",
    selfDefinedGender: "Sexe",
    sexualHealthHeader: "Santé Sexuelle",
    choose: "Choisir",
    true: "Oui",
    zipCodePlaceHolder: "Code postal",
    followUpEmails: "Courriels de suivi",
    unsubscribe: "Se désabonner",
    subscribe: "S’abonner",
    confirmPhoneNumber: "Confirmer mon numéro de téléphone",
    tfaPreference: "Préférence pour l’authentification en 2 étapes",
    phoneNumberMustBeConfirmed:
      "Tu dois confirmer ton numéro de téléphone pour utiliser les SMS comme méthode d’authentification à deux facteurs.",
    cancel: "Annuler",
    save: "Sauvegarder",
  },
  phoneConfirmationModal: {
    title: "Confirmation de ton numéro de téléphone",
    sentTo: (phone) =>
      `Un code de confirmation a été envoyé au ${phone} par SMS.`,
    instructions:
      "Entre-le dans le champ ci-dessous pour confirmer ton numéro de téléphone.",
    notReceived: "Tu n’as pas reçu le code?",
    resend: "Clique ici pour en recevoir un nouveau.",
    wrongPhone: "Ce n'est pas ton numéro de téléphone?",
    changePhone: "Clique ici pour changer ton profil.",
    placeholder: "Code à 6 chiffres",
    submit: "Confirmer",
    invalid: "Code invalide",
    successMessage: "Ton numéro de téléphone a été confirmé",
  },
  questionnaireComponent: {
    blockText: "ou appuie sur <strong>Entrée</strong>",
    incompleteQuestionnaireError: "Formulaire invalide",
    noRamqButton: "Je ne suis pas inscrit à la RAMQ",
    completeBtn: "Compléter",
    okBtn: "OK",
    yearLbl: "Année",
    phoneNumber: "Numéro de téléphone",
    requiredFieldError: "Champs requis",
    invalidFormError: "Il y a une/des erreur(s) dans le formulaire",
    selectRequiredFieldError: "Tu dois choisir une réponse",
    ramqNumberInvalidError: "Numéro de RAMQ invalide",
    phoneInvalidError: "Numéro de téléphone invalide",
    underFifteenError:
      "Les services Prélib s’adressent à toutes personnes âgées de 15 ans et plus",
  },
  questionnaireSection: {
    completeButtonText: "Compléter",
    header: "Formulaire sur la santé générale",
    takeQuestionnaire: "Faire questionnaire",
    modal: {
      consentButtonText: "Je consens",
      consentHeader: "Consentement",
      consentFirstP:
        "En cliquant ou appuyant sur le bouton « J'accepte » ci-après, j’indique que je consens à ce que Prélib inc. (« Prélib ») et ses médecins effectuent une ou plusieurs téléconsultations dans le cadre de dépistage asymptomatique en santé sexuelle via le questionnaire standardisé électronique. La téléconsultation est possible seulement pour les personnes physiquement au Québec. En cliquant sur le bouton « J'accepte », j’atteste être actuellement au Québec.",
      consentSecondP:
        "Je comprends et reconnais (i) que Prélib a recours à tous les moyens technologiques possibles pour contrer les risques de bris de confidentialité et/ou de perte de définition des images lors de la transmission, par voie électronique, de ces renseignements confidentiels (ii) qu'il existe des risques et des limitations liés à l'utilisation de la télémédecine et du présent site Web, y compris toutes limites technologiques liées à la téléconsultation et les risques dans le traitement des données (iii) que je ne dois pas utiliser le présent site Web pour des urgences médicales (iv) que Prélib pourra collecter, utiliser et partager mes renseignements personnels (et médicaux) conformément à la Politique de confidentialité publiée sur le présent site Web.",
      consentThirdP:
        "J'autorise l'ouverture d'un dossier patient médical électronique où seront déposées les informations transmises lors de cette ou ces consultations tout en assurant la confidentialité des renseignements personnels.",
      consentFourthP:
        "J'ai lu et j’accepte le présent consentement, j’accepte de retenir les services de Prélib et je consens aux soins de téléconsultation au moyen du site Web de Prélib.",
      continueButtonText: "Continuer",
      recommendationHeader: "Recommandations",
      recommendationSubHeader:
        "Prélib recommande fortement de consulter un médecin dans les situations suivantes",
      recommendationFirstP: "Symptômes ITSS présents",
      recommendationSecondP:
        "Exposition dans un délai de 72 heures à une relation sexuelle à risque afin d’évaluer la possibilité de prophylaxie post-exposition (PPE)",
      recommendationThirdP: "Grossesse",
      refuseButtonText: "Je refuse",
    },
  },
  datetimePicker: {
    chooseAnHour: "Choisir une Heure",
    timePickerPlaceHolder: "Heure",
  },
  recommendationsSection: {
    restartQuestionnaire: "Recommencer le questionnaire",
    choseDateButtonText: "Choisir la date",
    updateTestSelection: "Mettre à jour ma sélection de tests",
    customRecommendationsPresentation:
      "Selon tes réponses au questionnaire, voici des conseils personnalisés\u00A0:",
    condomRecommendation:
      "L’utilisation du condom lors de toute relation sexuelle réduit nettement tes chances de contracter une ITSS.",
    drugSharingRecommendation:
      "N’oublie pas de ne pas partager ni d’utiliser du matériel d’injection ou d’inhalation de drogues à usage unique lorsque tu consommes.",
    extraCharges: "*À noter que les frais des tests seront chargés sur place.",
    header: "Recommandations",
    importantNewEvaluation:
      "Tu disposes de 45 jours pour prendre rendez-vous, sans quoi une réévaluation médicale sera nécessaire.",
    importantCancelTest:
      "Au moment du rendez-vous, il te sera toujours possible de retirer une analyse non souhaitée.",
    importantDisabled:
      "Si tu es une personne à mobilité réduite, écris-nous avant ton rendez-vous à info@prelib.com afin d’obtenir l’adresse pour accéder au centre.",
    importantFee:
      "Les frais reliés au transport de 5 à 15 $ (si applicables) ou les frais de laboratoires privés Dynacare (si applicables) seront exigés au moment du rendez-vous.",
    importantHeader: "IMPORTANT",
    importantSymptoms:
      "Si avant ton rendez-vous tu remarques la présence de symptômes, il est recommandé de consulter un médecin immédiatement.",
    medicalTestsPresentation:
      "D’après tes réponses au questionnaire, nous avons présélectionné les tests appropriés. Cela dit, tu peux modifier la sélection de tests si tu le souhaites. Tes prélèvements recommandés sont\u00A0:",
    minimalTestsCountError: "Tu dois choisir au moins un test",
    ppeLink: "prophylaxie post-exposition (PPE).",
    ppeRecommendation:
      "Puisque tu as eu une relation sexuelle à risque récemment (moins de 72 heures), Prélib te recommande fortement de te rendre dans une clinique afin d’évaluer l’indication de prescrire ou non la",
    prepLink: "prophylaxie pré-exposition (PrEP)",
    prepRecommendation:
      "Puisque tes comportements sexuels présentent un risque considérable de contracter le VIH, renseigne-toi sur la",
    prepRecommendation2: "auprès d’une clinique spécialisée.",
    retakeRecommendationExplanation:
      "Faire le dépistage des maladies énoncées ci-dessus à répétition permettra de couvrir la période fenêtre, soit la période maximale avant laquelle l’infection peut être détectée dans le sang.",
    retakeRecommendationHeader:
      "Puisque les délais recommandés entre l’exposition potentielle et la validité des prélèvements varient selon l'infection, il peut être indiqué de refaire un dépistage selon les différentes périodes fenêtres:",
    retakeRecommendationHIV: "VIH : contrôle à faire à partir du",
    retakeRecommendationHepatiteC: "Hépatite C : contrôle à faire à partir du",
    retakeRecommendationSyphilis: "Syphilis : contrôle à faire à partir du",
    vaxRecommendation:
      "Ton profil de vaccination ne semble pas à jour, renseignes-toi auprès de ton médecin de famille ou en clinique spécialisée. C’est simple et souvent gratuit!",
    combinedOralNaatTest: "Chlamydia/Gonorrhée gorge (TAAN)",
    itssSlug: "itss",
    appointmentUpdated: "Ton rendez-vous a été mis-à-jour",
  },
  expiredSection: {
    header: "Ton questionnaire est expiré",
    restart: "Refaire le questionnaire",
    message:
      "Ton questionnaire a expiré.\n\nLe délai de 45 jours accordé pour la prise de rendez-vous a été dépassé. Ton questionnaire médical n’est donc plus valide et une réévaluation complète est nécessaire.\n\nSi tu as des questions, tu peux nous écrire à info@prelib.com et un membre de l’équipe te répondra rapidement.",
  },
  resultsSection: {
    invalidResultLabel: "Invalide",
    undisclosedResultLabel: "En attente",
    toRetakeResultLabel: "À reprendre",
    negativeResultLabel: "Négatif",
    positiveResultLabel: "Positif",
    notDoneResultLabel: "Non effectué",
    cancelled: "Annulé",
    resultsNotReadyLine1:
      "Tes résultats sont toujours en attente, mais cela ne devrait pas tarder",
    resultsNotReadyLine2:
      "Tu recevras un message lorsqu'ils seront disponibles",
    recommendations: "Recommandations:",
    recommendationsWhenCancel:
      "Bien que ton rendez-vous soit annulé, les conseils suivants s’appliquent toujours:",
    recommendationsWhenNoResult:
      "D'ici là, garde en tête les recommandations suivantes:",
    recommendationsWhenNotDone:
      "Même si ton rendez-vous n'a pas encore eu lieu, les conseils suivants demeurent pertinents:",
    giveUsYourFeedback: "Tu veux nous donner ton avis?",
    thankYouForFeedback: "Merci pour ton opinion",
    rowHeader: (date) => `Rendez-vous du ${date}`,
    positiveInfo: {
      row1_1: "Un de tes résultats est positif. ",
      row1_2:
        "Pour obtenir un traitement, Prélib travaille en collaboration avec la Clinique médicale Quorum où il est possible d’obtenir un rendez-vous rapide, si tu le désires. Tu peux prendre un rendez-vous en ligne sur le site ",
      row1_3: "www.cliniquequorum.com/portail-patient/",
      row1_4: ", par téléphone en composant le ",
      row1_5: "514-360-0614",
      row1_6:
        " ou en te présentant au sans rendez-vous lors des heures d’ouverture.",
      appointmentLink: "http://cliniquequorum.com/portail-patient/",
      appointmentPhonenumber: "tel:+15143600614",

      row3_1:
        "D’ici ton rendez-vous, il est bien important de s’abstenir de toutes relations sexuelles.",
      FAQLink: "https://www.prelib.com/fr#faqs",
    },
    pendingInfo: {
      header: "Résultat en attente :",
      explanation:
        "Un prélèvement «  en attente  » signifie qu'il n'a pas encore été analysé par le laboratoire. Ce délai d’analyse est normal et le résultat te sera acheminé sous peu (prévoir jusqu’à sept jours supplémentaires).",
    },
    retakeInfo: {
      row1_1:
        "Un de tes prélèvements n’a pas pu être interprété par le laboratoire. ",
      row1_2:
        "Il peut s’agir d’une erreur du laboratoire ou d’un échantillon nprofileSectionon conforme, mais ce n’est pas inquiétant.",
      row2_1:
        "Prélib t’offre de reprendre gratuitement (délai de 45 jours) ce prélèvement en prenant un rendez-vous au centre.",
      retakeButton: "Reprendre rendez-vous",
      rejectRetakeButton: "Je ne veux pas reprendre un rendez-vous",
    },
    schedulePhoneAppointment: {
      title:
        "Tu as été invité à un rendez-vous téléphonique. Choisis une date.",
      scheduleButton: "Prendre rendez-vous",
    },
  },
  sectionSelection: {
    appointmentsSection: {
      canceledAppointments: "Mes rendez-vous annulés",
      header: "Mes rendez-vous",
      pastAppointments: "Mes rendez-vous passés",
      upcomingAppointments: "Mes rendez-vous à venir",
      missedAppointments: "Mes rendez-vous manqués",
    },
    makeScreeningSection: {
      date: "Rendez-vous",
      header: "Faire un dépistage",
      information: "Informations",
      medicalEvaluation: "Évaluation médicale",
      payment: "Paiement",
      recommendations: "Recommandations",
      expired: "Questionnaire expiré",
      tooltipMessage: "Tu as déjà un rendez-vous prévu",
      appointmentCreated: "Ton rendez-vous a été prévu ",
    },
    myProfileSection: {
      generalHealth: "Santé générale",
      header: "Mon profil",
      personalInformation: "Informations personnelles",
      sexualHealth: "Santé sexuelle",
    },
    myResultsSection: {
      header: "Mes résultats",
      tooltipMessage: "Tu n'as aucun résultat",
    },
    welcomeTextBasic: "Bienvenue sur la plateforme Prélib",
    welcomeTextInfo: "Ton dernier dépistage remonte au",
    welcomeTextReminder: "Tu as un rendez-vous le",
  },
  resetPassword: {
    changeButtonText: "Changer",
    confirmationLabel: "Confirmer le mot de passe",
    header: "Nouveau mot de passe",
    invalidPasswordError: "Mot de passe invalide",
    passwordDontMatchError: "Les mots de passe diffèrent",
    invalidTokenError: "Le jeton est invalide!",
    passwordLabel: "Mot de passe",
    successMessage: "Ton mot de passe est changé!",
  },
  tfaInput: {
    title: "Authentification en 2 étapes",
    sentTo: (sentBy, sentTo) =>
      `Ton compte est protégé par une authentification en 2 étapes. Un code d’authentification à 6 chiffres a été envoyé par ${sentBy} à ${sentTo}.`,
    checkboxLabel:
      "Ne plus me demander le code pendant 30 jours lorsque j'utilise cet ordinateur.",
    fieldLabel: "Entrer le code à 6 chiffres :",
    codeError: "Code invalide",
    verifyButton: "Vérifier",
    resentCode: (sentBy, sentTo) =>
      `Un code d’authentification à 6 chiffres a été renvoyé par ${sentBy} à ${sentTo}.`,
    resendCode: "Renvoyer un code d’authentification à 6 chiffres",
  },
  tfaSettings: {
    title: "Vérification en 2 étapes",
    subtitle:
      "Pour la sécurité de tes données, ton compte sera maintenant protégé par une authentification en 2 étapes. Lors de la connexion, tu devras confirmer ton identité à partir d’un code. Par quelle méthode souhaites-tu recevoir ce code? ",
    confirmButton: "Continuer",
    bySms: "Par SMS",
    byEmail: "Par courriel",
    phoneConfirmationButton: "Confirmer le numéro de téléphone",
    phoneNumberLabel: "Numéro de téléphone",
    phoneNumberPlaceholder: "Format du numéro : (xxx) xxx-xxx",
    phoneNumberError: "Numéro de téléphone invalide",
  },
  select: {
    startTyping: "Commencer à écrire",
    keepTyping: "Continuer à écrire",
    noResults: "Aucun résultat",
  },
  months: [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ],
  humanizeDateFormatter: {
    unknownDate: "Aucune plage horaire n'est disponible pour le moment",
    nextAvailableTime: "La prochaine plage horaire disponible est",
  },
};
