import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { Loader } from "react-feather";
import {
  Button,
  Checkbox,
  PersonalRecommendations,
} from "../../../../components";
import useAuthUser from "../../../../hooks/auth-user";
import useLanguage from "../../../../hooks/language";
import ENDPOINTS from "../../../../utils/endpoints";
import ROUTES from "../../../../utils/routes";
import Appointment from "../../../../models/appointment";
import SuggestedMedicalTest, {
  sortSuggestedMedicalTest,
} from "../../../../models/suggested-medical-test";
import { getLocaleName } from "../../../../models/medical-test";

import styles from "./recommendations.module.css";

const CHLAMYDIA_ORAL_TAAN_TEST_NAME = "chlamydia_oral_taan";
const GONORRHEA_ORAL_TAAN_TEST_NAME = "gonorrhea_oral_taan";

interface RecommendationsProps {
  upcomingAppointment?: Appointment;
  selectedMedicalTests?: SuggestedMedicalTest[];
  onRestartQuestionnaireClick: () => void;
  onSelectMedicalTests: (test: SuggestedMedicalTest[]) => void;
}

const Recommendations: React.FunctionComponent<RecommendationsProps> = ({
  upcomingAppointment,
  selectedMedicalTests: preSelectedMedicalTests,
  onSelectMedicalTests,
  onRestartQuestionnaireClick,
}: RecommendationsProps) => {
  const { push } = useHistory();
  const [authUser, setAuthUser] = useAuthUser();
  const { translations, language } = useLanguage();
  const t = translations.recommendationsSection;

  // States

  const [suggestedMedicalTests, setSuggestedMedicalTests] = useState<
    null | SuggestedMedicalTest[]
  >(null);
  const [selectedMedicalTests, setSelectedMedicalTests] = useState<
    SuggestedMedicalTest[]
  >(preSelectedMedicalTests ?? []);
  const [isLoading, setLoading] = useState(false);

  // Effects

  useEffect(() => {
    if (!authUser || !authUser.lastUserQuestionnaire) return;
    fetchRecommendations(authUser.lastUserQuestionnaire.id);
  }, [authUser]);

  useEffect(() => {
    if (!suggestedMedicalTests) return;
    if (!preSelectedMedicalTests)
      setSelectedMedicalTests([...suggestedMedicalTests]);
  }, [suggestedMedicalTests]);

  // Networks

  const fetchRecommendations = async (userQuestionnaireId: number) => {
    const { data } = await Axios.get(
      ENDPOINTS.MEDICAL_TESTS_RECOMMENDATIONS(userQuestionnaireId)
    );
    setSuggestedMedicalTests(sortSuggestedMedicalTest(data, language));
  };

  // Handlers

  const toggleTest = (test: SuggestedMedicalTest) => {
    const index = selectedMedicalTests.findIndex(({ id }) => id === test.id);

    const tests = [...selectedMedicalTests];

    if (index > -1) {
      tests.splice(index, 1);
    } else {
      tests.push(test);
    }

    setSelectedMedicalTests(tests);
  };

  const toggleOralTaanTest = () => {
    const chlamydiaOralTaanTest = suggestedMedicalTests
      ? suggestedMedicalTests.find(
          ({ medicalTest }) =>
            medicalTest.name === CHLAMYDIA_ORAL_TAAN_TEST_NAME
        )
      : null;
    const gonorrheaOralTaanTest = suggestedMedicalTests
      ? suggestedMedicalTests.find(
          ({ medicalTest }) =>
            medicalTest.name === GONORRHEA_ORAL_TAAN_TEST_NAME
        )
      : null;

    const tests = [...selectedMedicalTests];

    // Toggle chlamydia test
    const chosenChlamydiaIndex = chlamydiaOralTaanTest
      ? tests.findIndex((t) => t.id === chlamydiaOralTaanTest.id)
      : -1;
    if (chosenChlamydiaIndex > -1) {
      tests.splice(chosenChlamydiaIndex, 1);
    } else if (chlamydiaOralTaanTest) {
      tests.push(chlamydiaOralTaanTest);
    }

    // Toggle gonorrhea test
    const chosenGonorrheaIndex = gonorrheaOralTaanTest
      ? tests.findIndex((t) => t.id === gonorrheaOralTaanTest.id)
      : -1;
    if (chosenGonorrheaIndex > -1) {
      tests.splice(chosenGonorrheaIndex, 1);
    } else if (gonorrheaOralTaanTest) {
      tests.push(gonorrheaOralTaanTest);
    }

    setSelectedMedicalTests(tests);
  };

  const onSubmit = () => {
    if (upcomingAppointment) {
      updateAppointment();
    } else {
      onSelectMedicalTests(selectedMedicalTests);
    }
  };

  // Helpers

  const isTestSelected = ({ id }: SuggestedMedicalTest) =>
    selectedMedicalTests.findIndex(({ id: _id }) => _id === id) >= 0;

  const isOralTaanSelected = () => {
    const chlamydiaOralTaanTest = suggestedMedicalTests
      ? suggestedMedicalTests.find(
          ({ medicalTest }) =>
            medicalTest.name === CHLAMYDIA_ORAL_TAAN_TEST_NAME
        )
      : null;
    const gonorrheaOralTaanTest = suggestedMedicalTests
      ? suggestedMedicalTests.find(
          ({ medicalTest }) =>
            medicalTest.name === GONORRHEA_ORAL_TAAN_TEST_NAME
        )
      : null;

    if (chlamydiaOralTaanTest && isTestSelected(chlamydiaOralTaanTest))
      return true;
    if (gonorrheaOralTaanTest && isTestSelected(gonorrheaOralTaanTest))
      return true;

    return false;
  };

  // Network

  const updateAppointment = async () => {
    if (!authUser || !upcomingAppointment) return;

    setLoading(true);

    const { lastUserQuestionnaireId: userQuestionnaireId } = authUser;
    const chosenMedicalTests = selectedMedicalTests.map(({ medicalTest }) => ({
      medicalTestId: medicalTest.id,
    }));

    const body = {
      userQuestionnaireId,
      chosenMedicalTests,
    };

    const { id } = upcomingAppointment;

    try {
      await Axios.put(ENDPOINTS.UPDATE_APPOINTMENT_TESTS(id), body);
      const { data } = await Axios.get(ENDPOINTS.ME);
      setAuthUser(data);
      NotificationManager.success(t.appointmentUpdated);
      push(ROUTES.HOME);
    } catch {
      setLoading(false);
    }
  };

  // Rendering

  const renderOralTaanTest = () => (
    // Random key LOOOOOL
    <li key={46548}>
      <Checkbox
        checked={isOralTaanSelected()}
        onChange={() => toggleOralTaanTest()}
      />
      {t.combinedOralNaatTest}
    </li>
  );

  const renderTest = (test: SuggestedMedicalTest) => {
    if (
      test.medicalTest.name === CHLAMYDIA_ORAL_TAAN_TEST_NAME ||
      test.medicalTest.name === GONORRHEA_ORAL_TAAN_TEST_NAME
    )
      return;
    return (
      <li key={test.id}>
        <Checkbox
          checked={isTestSelected(test)}
          onChange={() => toggleTest(test)}
        />
        {getLocaleName(test.medicalTest, language)}
      </li>
    );
  };

  const shouldTakeOralTaan =
    suggestedMedicalTests &&
    suggestedMedicalTests.some(
      ({ medicalTest }) =>
        medicalTest.name === CHLAMYDIA_ORAL_TAAN_TEST_NAME ||
        medicalTest.name === GONORRHEA_ORAL_TAAN_TEST_NAME
    );

  return (
    <div className={`${styles.holder} container`}>
      <h2>{t.header}</h2>
      <p>{t.medicalTestsPresentation}</p>
      {!suggestedMedicalTests && (
        <div>
          <Loader className="spinorama" />
          <br />
          <br />
        </div>
      )}
      {suggestedMedicalTests && (
        <ul className={styles.list}>
          {shouldTakeOralTaan && renderOralTaanTest()}
          {suggestedMedicalTests.map(renderTest)}
        </ul>
      )}
      <br />
      <b>{t.customRecommendationsPresentation}</b>
      {authUser && authUser.lastUserQuestionnaire && (
        <PersonalRecommendations
          userQuestionnaire={authUser.lastUserQuestionnaire}
        />
      )}
      <div className={styles.infoHolder}>
        <h5>{t.importantHeader}</h5>
        <p>{t.importantSymptoms}</p>
        <p>{t.importantNewEvaluation}</p>
        <p>{t.importantFee}</p>
      </div>
      <p className="disclaimer">{t.extraCharges}</p>
      <div className="dual-button-holder flex-end">
        <Button type="secondary" onClick={onRestartQuestionnaireClick}>
          {t.restartQuestionnaire}
        </Button>
        <Button
          loading={isLoading}
          disabled={selectedMedicalTests.length === 0 || !suggestedMedicalTests}
          onClick={onSubmit}
        >
          {upcomingAppointment ? t.updateTestSelection : t.choseDateButtonText}
        </Button>
      </div>
    </div>
  );
};

export default Recommendations;
