import React, { useState, useEffect, useLayoutEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import Axios from "axios";
import { get } from "lodash";
import ReactGA from "react-ga";
import {
  persistAuthToken,
  getPersistedAuthToken,
  getPersistedUserLanguage,
  persistUserLanguage,
} from "./utils/local-storage";
import { getTranslations } from "./translations";
import AppContext from "./context/app";
import User from "./models/user";
import { UserLanguage } from "./utils/types";
import ROUTES from "./utils/routes";
import ENDPOINTS from "utils/endpoints";

// Pages

import WidgetPage from "./pages/widgets";
import LoginPage from "./pages/login";
import TfaInputPage from "./pages/tfa-input";
import TfaSettingsPage from "./pages/tfa-settings";
import EmailConfirmationPage from "./pages/email-confirmation";
import PasswordForgottenPage from "./pages/password-forgotten";
import PasswordResetPage from "./pages/password-reset";
import UnsubscribePage from "./pages/unsubscribe";
import HomePage from "./pages/home";
import ProfilePage from "./pages/profile";

const REACT_APP_GA_KEY = process.env.REACT_APP_GA_KEY || "";

const App: React.FunctionComponent = () => {
  const location = useLocation();

  // States and refs

  const [authToken, setAuthToken] = useState<string | null>(
    getPersistedAuthToken()
  );
  const [authUser, setAuthUser] = useState<User | null>(null);
  const [language, setLanguage] = useState<UserLanguage>(
    getPersistedUserLanguage()
  );

  // Effects
  useEffect(() => {
    ReactGA.initialize(REACT_APP_GA_KEY);
  }, []);

  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, [location]);

  useLayoutEffect(() => {
    // Persist the token
    persistAuthToken(authToken);

    // If no auth token, no auth user
    if (!authToken) setAuthUser(null);

    // Update axios configs to change the used auth token
    const requestInterceptor = Axios.interceptors.request.use(
      (config) => {
        config.headers["x-access-token"] = authToken;
        return config;
      },
      (error) => Promise.reject(error)
    );

    return () => {
      Axios.interceptors.request.eject(requestInterceptor);
    };
  }, [authToken, setAuthUser]);

  useEffect(() => {
    // Fetch auth user
    if (authToken !== null && authToken !== undefined) fetchMe();
  }, [authToken]);

  useEffect(() => {
    const responseInterceptor = Axios.interceptors.response.use(
      (res) => res,
      (error) => {
        const errorStatus = get(error, ["response", "data", "status"], 400);
        if (errorStatus === 401 || errorStatus === 403) setAuthToken(null);
        return Promise.reject(error);
      }
    );

    return () => {
      Axios.interceptors.request.eject(responseInterceptor);
    };
  }, [setAuthToken]);

  useEffect(() => {
    persistUserLanguage(language);

    // TODO: If user is connected, API call to update user preference
  }, [language]);

  // Network

  const fetchMe = async () => {
    try {
      const { data } = await Axios.get(ENDPOINTS.ME);
      setAuthUser(data);
    } catch (e) {
      setAuthToken(null);
    }
  };

  // Rendering

  const translations = getTranslations(language);

  return (
    <AppContext.Provider
      value={{
        authToken,
        setAuthToken,
        authUser,
        setAuthUser,
        language,
        setLanguage,
        translations,
      }}
    >
      <Switch>
        <Route exact path="/widgets">
          <WidgetPage />
        </Route>
        <Route exact path={ROUTES.LOGIN}>
          <LoginPage />
        </Route>
        <Route exact path={ROUTES.TFA_INPUT}>
          <TfaInputPage />
        </Route>
        <Route exact path={ROUTES.TFA_SETTINGS}>
          <TfaSettingsPage />
        </Route>
        <Route exact path={ROUTES.EMAIL_CONFIRMATION}>
          <EmailConfirmationPage />
        </Route>
        <Route exact path={ROUTES.PASSWORD_FORGOTTEN}>
          <PasswordForgottenPage />
        </Route>
        <Route exact path={ROUTES.PASSWORD_RESET}>
          <PasswordResetPage />
        </Route>
        <Route exact path={ROUTES.UNSUBSCRIBE}>
          <UnsubscribePage />
        </Route>

        {!authToken && <Redirect to={ROUTES.LOGIN} />}

        <Route path={ROUTES.HOME}>
          <HomePage />
        </Route>
        <Route exact path={ROUTES.PROFILE}>
          <ProfilePage />
        </Route>
        <Redirect to={ROUTES.HOME} />
      </Switch>
    </AppContext.Provider>
  );
};

export default App;
