import React, {
  ChangeEvent,
  KeyboardEvent,
  FocusEvent,
  useState,
  useRef,
  useEffect,
  RefObject,
} from "react";
import { Loader } from "react-feather";
import { Selection } from "../index";

import MultipleSelectionCell from "./multiple-selection-cell";

import styles from "./multiple-selection-field.module.css";

interface MultipleSelectionFieldInterface {
  selectedElements: Selection[];
  icon?: React.ReactNode;
  disabled?: boolean;
  loading?: boolean;
  readOnly?: boolean;
  error?: string;
  value?: string;
  placeholder?: string;
  onRemoveElementAtIndex: (index: number) => void;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  onIconClick?: () => void;
}

const MultipleSelectionField: React.FunctionComponent<MultipleSelectionFieldInterface> = ({
  selectedElements,
  icon,
  disabled = false,
  loading = false,
  readOnly = false,
  error,
  value,
  placeholder,
  onRemoveElementAtIndex,
  onChange,
  onKeyDown,
  onFocus,
  onBlur,
  onIconClick,
}: MultipleSelectionFieldInterface) => {
  // States and refs

  const _inputRef: RefObject<HTMLInputElement> | null = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [_value, setValue] = useState(value || "");

  // Effects

  useEffect(() => {
    if (value !== undefined) setValue(value);
  }, [value]);

  // Handlers

  const onClick = () => {
    if (_inputRef && _inputRef.current) _inputRef.current.focus();
  };

  const _onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    if (onChange) onChange(event);
  };

  const _onFocus = (event: FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
    if (onFocus) onFocus(event);
  };

  const _onBlur = (event: FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    if (onBlur) onBlur(event);
  };

  const _onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (
      event.key === "Backspace" &&
      selectedElements.length > 0 &&
      _value.length === 0
    )
      onRemoveElementAtIndex(selectedElements.length - 1);
    if (onKeyDown) onKeyDown(event);
  };

  // Rendering

  const inputWidth =
    selectedElements.length === 0 && _value.length === 0 && placeholder
      ? placeholder.length * 15
      : _value.length * 15;

  let fieldClassName = styles.field;
  if (isFocused) fieldClassName += ` ${styles.focused}`;
  if (disabled) fieldClassName += ` ${styles.disabled}`;

  return (
    <div
      className={error ? `${styles.holder} ${styles.hasError}` : styles.holder}
    >
      <div
        className={fieldClassName}
        onClick={onClick}
        style={{ cursor: readOnly ? "pointer" : "text" }}
      >
        {selectedElements.map(({ value, display }, i) => (
          <MultipleSelectionCell
            key={i}
            value={display || `${value}`}
            onClickRemove={() => onRemoveElementAtIndex(i)}
          />
        ))}
        <input
          type="text"
          ref={_inputRef}
          value={value}
          autoComplete="off"
          disabled={disabled}
          readOnly={readOnly}
          onChange={_onChange}
          onFocus={_onFocus}
          onBlur={_onBlur}
          onKeyDown={_onKeyDown}
          placeholder={selectedElements.length === 0 ? placeholder : undefined}
          style={{ width: inputWidth }}
        />
        <span
          className={styles.icon}
          onClick={onIconClick}
          style={{ pointerEvents: onIconClick ? "all" : "none" }}
        >
          {loading && <Loader className="spinorama" />}
          {!loading && icon}
        </span>
      </div>
      {error && <label>{error}</label>}
    </div>
  );
};

export default MultipleSelectionField;
