import React, { useEffect, useState } from "react";
import useLanguage from "../../../hooks/language";
import { Button } from "../../../components";
import { NotificationManager } from "react-notifications";
import Clinic from "../../../models/clinic";
import ClinicList from "../../../components/clinic-list";
import Axios from "axios";
import ENDPOINTS from "../../../utils/endpoints";

interface DateSelectionProps {
  onSubmit?: (isoDate: string, clinicId: number) => void;
  loading?: boolean;
}

const DateSelection: React.FunctionComponent<DateSelectionProps> = ({
  onSubmit,
  loading = false,
}: DateSelectionProps) => {
  const { translations } = useLanguage();
  const t = translations.dateSection;

  // States

  const [isoDate, setIsoDate] = useState<string | null>(null);
  const [clinics, setClinics] = useState<Clinic[]>([]);
  const [selectedClinic, setSelectedClinic] = useState<number | null>(null);

  // Effects

  // Handlers
  useEffect(() => {
    fetchClinics();
  }, []);

  // Networking

  const fetchClinics = async () => {
    try {
      const { data } = await Axios.get(ENDPOINTS.CLINICS);
      setClinics(data);
    } catch (error) {
      console.log(error);
      NotificationManager.error(error);
    }
  };

  const _onSubmit = () => {
    if (onSubmit != null && isoDate != null && selectedClinic != null) {
      onSubmit(isoDate, selectedClinic);
    }
  };

  // Rendering

  return (
    <div className="container">
      <h2>{t.header}</h2>
      <ClinicList
        clinics={clinics}
        onDateSelect={setIsoDate}
        onClinicSelect={setSelectedClinic}
        hasDateSelect={true}
      />
      <br />
      <div className="dual-button-holder flex-end">
        <Button
          disabled={!isoDate || !selectedClinic}
          onClick={_onSubmit}
          loading={loading}
        >
          {t.submitButtonText}
        </Button>
      </div>
    </div>
  );
};

export default DateSelection;
