import React, { useState } from "react";
import Axios from "axios";
import { NotificationManager } from "react-notifications";
import useLanguage from "../../hooks/language";
import { AppointmentDateTimePicker, Button } from "../../components";
import PhoneAppointment from "../../models/phone-appointment";
import ENDPOINTS from "../../utils/endpoints";

interface SchedulePhoneAppointmentModalProps {
  onClose?: () => void;
  onConfirm?: () => void;
  appointment?: PhoneAppointment;
}

const SchedulePhoneAppointmentModal: React.FunctionComponent<SchedulePhoneAppointmentModalProps> = ({
  appointment,
  onConfirm,
  onClose,
}: SchedulePhoneAppointmentModalProps) => {
  const { translations } = useLanguage();
  const t = translations.appointmentsSection;

  // states
  const [loading, setLoading] = useState<boolean>(false);
  const [isoDate, setIsoDate] = useState<string | null>(null);

  // Network

  const updateAppointment = async () => {
    if (!appointment) return;

    setLoading(true);

    try {
      await Axios.put(ENDPOINTS.SCHEDULE_PHONE_APPOINTMENT(appointment.id), {
        startAt: isoDate,
      });
      if (onConfirm) await onConfirm();
      NotificationManager.success(
        translations.notification.rescheduledAppointment
      );
    } finally {
      setLoading(false);
    }
  };
  // Rendering

  return (
    // TODO Feriel le clinic Id est hardcoder le changer pour la clinic du appointment
    <div>
      <AppointmentDateTimePicker
        appointment={appointment}
        onSelectDate={setIsoDate}
        clinicId={1}
      />
      <br />
      <div className="dual-button-holder">
        <Button onClick={onClose} type="secondary">
          {t.cancellationModal.exitButtonText}
        </Button>
        <Button
          disabled={!isoDate}
          loading={loading}
          onClick={updateAppointment}
        >
          {t.cancellationModal.confirmButtonText}
        </Button>
      </div>
    </div>
  );
};

export default SchedulePhoneAppointmentModal;
