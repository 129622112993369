import React from "react";
import { Frown, Meh, Smile } from "react-feather";

import styles from "./rating-input.module.css";
import { SatisfactionForm } from "../index";

interface RatingInputProps {
  onChange?: (test: any) => void;
  inputKey: keyof SatisfactionForm;
}
const RatingInput: React.FunctionComponent<RatingInputProps> = ({
  onChange,
  inputKey,
}: RatingInputProps) => {
  // State

  const [selectedValue, setSelectedValue] = React.useState(null);

  // Handlers

  const onSelectValue = (value: any) => {
    setSelectedValue(value);
    if (onChange) onChange({ [inputKey]: value });
  };

  // Rendering

  const renderRatingButton = (value: number) => {
    let className = styles.ratingButton;
    let icon = null;

    if (value < 7) {
      className += ` ${styles.frown}`;
      icon = <Frown />;
    } else if (value >= 7 && value <= 8) {
      className += ` ${styles.meh}`;
      icon = <Meh />;
    } else if (value > 8) {
      className += ` ${styles.smile}`;
      icon = <Smile />;
    }

    if (selectedValue === value) className += ` ${styles.selected}`;

    return (
      <button
        key={`rating_${value}`}
        className={className}
        onClick={() => onSelectValue(value)}
      >
        {icon}
        <span>{value}</span>
      </button>
    );
  };

  return (
    <div className={styles.ratingInput}>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(renderRatingButton)}
    </div>
  );
};

export default RatingInput;
