export const formatCoupon = (coupon: any, language: string): string => {
  if (coupon.amount_off) {
    const localMoneyFormatter = new Intl.NumberFormat(`${language}-CA`, {
      style: "currency",
      currency: "CAD",
    });
    return localMoneyFormatter.format(coupon.amount_off / 100);
  }

  if (coupon.percent_off) {
    const localPercentFormatter = new Intl.NumberFormat(`${language}-CA`, {
      style: "decimal",
    });
    return `${localPercentFormatter.format(coupon.percent_off)}%`;
  }

  return "";
};
