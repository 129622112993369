import React, {
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  useState,
  RefObject,
  cloneElement,
} from "react";
import { Loader } from "react-feather";
import styles from "./field.module.css";

interface FieldInterface {
  icon?: React.ReactNode;
  className?: string;
  fieldClassName?: string;
  disabled?: boolean;
  loading?: boolean;
  readOnly?: boolean;
  textArea?: boolean;
  error?: string | null;
  type?: string;
  value?: string;
  placeholder?: string;
  autoComplete?: string;
  rows?: number;
  min?: number;
  inputRef?: RefObject<HTMLInputElement>;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  onPressEnter?: () => void;
  onIconClick?: () => void;
}

const Field: React.FunctionComponent<FieldInterface> = ({
  icon,
  className,
  fieldClassName,
  inputRef,
  disabled = false,
  loading = false,
  readOnly = false,
  textArea = false,
  autoComplete,
  type = "text",
  error,
  value,
  min,
  rows,
  placeholder,
  onChange,
  onKeyDown,
  onFocus,
  onBlur,
  onPressEnter,
  onIconClick,
}: FieldInterface) => {
  const [isFocused, setIsFocused] = useState(false);

  // Handlers

  const _onFocus = (event: FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
    if (onFocus) onFocus(event);
  };

  const _onBlur = (event: FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    if (onBlur) onBlur(event);
  };

  const _onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && onPressEnter) {
      event.preventDefault();
      onPressEnter();
    }
    if (onKeyDown) onKeyDown(event);
  };

  // Rendering

  let rootClass =
    type === error ? `${styles.holder} ${styles.hasError}` : styles.holder;
  if (className) rootClass += ` ${className}`;
  if (error) rootClass += ` ${styles.hasError}`;

  let rootStyle = {};
  if (rows && textArea)
    rootStyle = { ...rootStyle, ...{ height: `${rows * 34}px` } };

  let _fieldClassName = styles.field;
  if (isFocused) _fieldClassName += ` ${styles.focused}`;
  if (disabled) _fieldClassName += ` ${styles.disabled}`;
  if (fieldClassName) _fieldClassName += ` ${fieldClassName}`;

  let fieldClassStyle = {};
  if (rows && textArea) fieldClassStyle = { height: "inherit" };

  const augmentedProps = {
    disabled,
    readOnly,
    ref: inputRef,
    type,
    min,
    value,
    autoComplete,
    onChange,
    onKeyDown: _onKeyDown,
    onFocus: _onFocus,
    onBlur: _onBlur,
    placeholder,
    style: {
      paddingRight: loading || icon ? 50 : 20,
    },
  };

  return (
    <div className={rootClass} style={rootStyle}>
      <div className={_fieldClassName} style={fieldClassStyle}>
        <div>
          {textArea
            ? cloneElement(<textarea rows={rows} />, augmentedProps)
            : cloneElement(<input />, augmentedProps)}
        </div>
        <span
          className={styles.icon}
          onClick={onIconClick}
          style={{ pointerEvents: onIconClick ? "all" : "none" }}
        >
          {loading && <Loader className="spinorama" />}
          {!loading && icon}
        </span>
      </div>
      {error && <label>{error}</label>}
    </div>
  );
};

export default Field;
