import React, { ChangeEvent, useState, useRef, useEffect } from "react";
import {
  Button,
  ButtonSelect,
  DateSelect,
  Field,
  Select,
  Option,
} from "../index";
import { Selection } from "../select";
import Question, {
  extractLocaleQuestion,
  extractQuestionError,
  findAnswerFromValue,
  findAnswerFromLocaleAnswer,
  canSubmitQuestion,
  getYearsArray,
} from "../../models/question";
import { extractLocaleAnswer, sortAnswersPredicate } from "../../models/answer";
import { normalizeString } from "../../utils/validation";
import useLanguage from "../../hooks/language";

import styles from "./questionnaire.module.css";

interface QuestionBlockProps {
  question: Question;
  focused?: boolean;
  showError?: boolean;
  onChange: (answer: any) => void;
  onSubmit: () => void;
  onClick?: () => void;
}

const QuestionBlock: React.FunctionComponent<QuestionBlockProps> = ({
  question,
  onChange,
  focused = true,
  showError = false,
  onSubmit,
  onClick,
}: QuestionBlockProps) => {
  const { language, translations } = useLanguage();
  const t = translations.questionnaireComponent;
  const error = extractQuestionError(question, translations);

  // Refs

  const inputRef = useRef<HTMLInputElement>(null);

  // States

  const [submitted, setSubmitted] = useState(false);
  const [filter, setFilter] = useState<string | undefined>();

  // Effects

  useEffect(() => {
    if (focused && inputRef && inputRef.current) inputRef.current.focus();
  }, [focused]);

  useEffect(() => {
    if (question.subType === "autocomplete" && filter !== undefined) {
      onChange({ rawValue: filter });
    }
  }, [filter]);

  // Handlers

  const onClickNoRamqButton = () => {
    onChange({ rawValue: "" });
    _onSubmit();
  };

  const onFieldChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    onChange({ rawValue: target.value });
  };

  const onDateChange = (date: Date) => {
    onChange({ rawValue: date.toISOString() });
  };

  const onSelect = (selection: Selection | undefined | null | Selection[]) => {
    if (!selection) return;

    if (
      !Array.isArray(selection) &&
      (question.type === "radio" || question.subType === "autocomplete")
    ) {
      const answer = findAnswerFromValue(question, selection.value as string);
      if (answer !== question.answer) _onSubmit();
      onChange(answer);
    } else if (Array.isArray(selection) && question.type === "checkbox") {
      const answers = selection.map(({ value }) =>
        findAnswerFromValue(question, value as string)
      );
      onChange(answers);
    }
  };

  const onButtonSelect = (selection: null | string | string[]) => {
    if (!selection) return;

    if (!Array.isArray(selection)) {
      const answer = findAnswerFromLocaleAnswer(question, selection, language);
      if (answer !== question.answer) _onSubmit();
      onChange(answer);
    } else if (Array.isArray(selection) && question.type === "checkbox") {
      const answers = selection.map((localValue) =>
        findAnswerFromLocaleAnswer(question, localValue, language)
      );
      onChange(answers);
    }
  };

  const onSelectYear = (
    selection: Selection | undefined | null | Selection[]
  ) => {
    if (!selection || Array.isArray(selection)) return;

    onChange({ rawValue: selection.value });
    _onSubmit();
  };

  const _onSubmit = () => {
    setSubmitted(true);
    // Single selection error should always submit if desired
    if (
      !error ||
      ["radio", "year"].indexOf(question.type) > -1 ||
      question.subType === "autocomplete"
    )
      onSubmit();
  };

  // Helpers

  const getSelection = (): Selection | Selection[] | null => {
    if (!question.answer || !question.answer.rawValue) return null;

    if (question.type === "radio" || question.subType === "autocomplete") {
      return {
        value: question.answer.rawValue,
        key: question.answer.rawValue,
        display: extractLocaleAnswer(question.answer, language),
      };
    }

    return null;
  };

  // Rendering

  const shouldShowError = showError || submitted;

  const renderSelectOptions = () => {
    let answers = question.answers.sort(sortAnswersPredicate);
    if (question.subType === "autocomplete") {
      answers = question.answers.filter((answer) =>
        normalizeString(extractLocaleAnswer(answer, language))
          .toLowerCase()
          .includes(normalizeString(filter || "").toLowerCase())
      );
    }

    return answers.map((answer) => (
      <Option
        value={answer.rawValue}
        key={answer.rawValue}
        display={extractLocaleAnswer(answer, language)}
      >
        {extractLocaleAnswer(answer, language)}
      </Option>
    ));
  };

  const renderYearOptions = () => {
    const years = getYearsArray();
    return years.map((year) => (
      <Option value={year} key={year}>
        {year}
      </Option>
    ));
  };

  const renderInput = () => {
    switch (question.type) {
      case "date":
        return (
          <DateSelect
            onChange={onDateChange}
            maxDate={new Date()}
            error={shouldShowError ? error : undefined}
            date={
              question.answer.rawValue
                ? new Date(question.answer.rawValue)
                : new Date()
            }
          />
        );
      case "radio":
      case "checkbox":
        // TODO: No preselection possible for checkbox questions, might be usefull eventually
        // eslint-disable-next-line no-case-declarations
        const selection = getSelection();
        // eslint-disable-next-line no-case-declarations
        const sortedAnswers = question.answers
          .sort(sortAnswersPredicate)
          .map((answer) => extractLocaleAnswer(answer, language));
        return (
          <ButtonSelect
            selection={
              selection !== null && !Array.isArray(selection)
                ? selection.display
                : undefined
            }
            options={sortedAnswers}
            onChange={onButtonSelect}
            multiple={question.type === "checkbox"}
          />
        );

      case "year":
        return (
          // TODO: No preselection possible for years, might be usefull eventually
          <Select
            error={shouldShowError ? error : undefined}
            onSelect={onSelectYear}
          >
            {renderYearOptions()}
          </Select>
        );
      case "number":
      case "text":
        if (question.subType === "autocomplete") {
          return (
            <Select
              search
              defaultSelection={getSelection() ?? undefined}
              onSelect={onSelect}
              onClear={() => setFilter("")}
              error={shouldShowError ? error : undefined}
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            >
              {renderSelectOptions()}
            </Select>
          );
        } else {
          return (
            <Field
              type={question.type === "number" ? "number" : undefined}
              min={question.type === "number" ? 0 : undefined}
              error={shouldShowError ? error : undefined}
              value={question.answer?.rawValue}
              onChange={onFieldChange}
              onPressEnter={_onSubmit}
              inputRef={inputRef}
            />
          );
        }
    }
  };

  const okDisabled =
    !canSubmitQuestion(question) || (error !== undefined && shouldShowError);

  return (
    <div
      className={`${styles.block} ${focused ? styles.focused : ""}`}
      key={question.id}
      onClick={onClick}
    >
      <p>
        {question.index}. {extractLocaleQuestion(question, language)}
      </p>
      {renderInput()}
      <div className="dual-button-holder flex-start">
        {question.subType === "ramq" && (
          <Button onClick={onClickNoRamqButton} type="secondary">
            {t.noRamqButton}
          </Button>
        )}
        {question.type !== "radio" && question.subType !== "autocomplete" && (
          <Button onClick={_onSubmit} disabled={okDisabled}>
            {t.okBtn}
          </Button>
        )}
      </div>
    </div>
  );
};

export default QuestionBlock;
