import PersonalInfo from "pages/home/screening/personal-info";
import Questionnaire from "../models/questionnaire";
import Answer from "../models/answer";
import User from "../models/user";
import countries from "./countries.json";

export interface PersonalInfo {
  val: string;
  fr: string;
  en: string;
}

export const SEXUAL_ORIENTATION_OPTIONS: PersonalInfo[] = [
  { val: "gay", fr: "Gay", en: "Gay" },
  { val: "hetero", fr: "Hétéro", en: "Hetero" },
];

export const GENDER_OPTIONS: PersonalInfo[] = [
  {
    val: "no_disclosure",
    fr: "Je préfère ne pas répondre",
    en: "I prefer not to answer",
  },
  { val: "male", fr: "Homme", en: "Men" },
  { val: "female", fr: "Femme", en: "Women" },
];

export const SELF_DEFINED_GENDER_OPTIONS: PersonalInfo[] = [
  {
    val: "no_disclosure",
    fr: "Je préfère ne pas répondre",
    en: "I prefer not to answer",
  },
  { val: "other", fr: "Autre", en: "Other" },
  { val: "non-binary", fr: "Non-binaire", en: "None-binary" },
  { val: "male-trans", fr: "Homme-trans", en: "Trans-man" },
  { val: "male", fr: "Homme", en: "Man" },
  { val: "female-trans", fr: "Femme-trans", en: "Trans-woman" },
  { val: "female", fr: "Femme", en: "Woman" },
];

export const TFA_PREFERENCE_OPTIONS: PersonalInfo[] = [
  { val: "sms", fr: "SMS", en: "SMS" },
  { val: "email", fr: "Courriel", en: "E-mail" },
];

const mapPersonalInfoToAnswer = (
  { val, fr, en }: PersonalInfo,
  index: number
): Answer => ({
  rawValue: val,
  frTranslation: fr,
  enTranslation: en,
  order: index,
});

export const buildInfoQuestionnaire = (user: User): Questionnaire => {
  const selfDefinedGenderAnswers = SELF_DEFINED_GENDER_OPTIONS.map(
    mapPersonalInfoToAnswer
  );
  const selfDefinedGenderAnswer = selfDefinedGenderAnswers.find(
    ({ rawValue }) => rawValue === user.selfDefinedGender
  );

  const genderAnswers = GENDER_OPTIONS.map(mapPersonalInfoToAnswer);
  const genderAnswer = genderAnswers.find(
    ({ rawValue }) => rawValue === user.bornGender
  );

  const countryAnswer = countries.find(
    ({ rawValue }) => rawValue === user.country
  );

  return {
    questions: [
      {
        id: 5,
        type: "text",
        key: "ramqNumber",
        index: "01",
        subType: "ramq",
        enTranslation: "What is your Québec health insurance number (RAMQ)?",
        frTranslation: "Quel est ton numéro d'assurance maladie (RAMQ)\u202F?",
        answers: [],
        answer: {
          rawValue: user.ramqNumber ?? undefined,
        },
      },
      {
        id: 6,
        type: "date",
        key: "birthDate",
        index: "02",
        enTranslation: "What is your date of birth ?",
        frTranslation:
          "Ta date de naissance est donc la date ci-dessous, est-ce exact\u202F?",
        answers: [],
        answer: {
          rawValue: user.birthDate ?? undefined,
        },
      },
      {
        id: 7,
        type: "text",
        key: "phone",
        index: "03",
        subType: "phone",
        enTranslation:
          "In the event of a positive test result for an STBBI, we will need to contact you by phone to direct you to a health professional. Which phone number can we reach you at?",
        frTranslation:
          "Advenant un résultat positif pour une ITSS, il nous faudra communiquer avec toi par téléphone afin de t'orienter vers un professionnel de la santé. À quel numéro peut-on te rejoindre\u202F?",
        answers: [],
        answer: {
          rawValue: user.phone ?? undefined,
        },
      },
      {
        id: 8,
        type: "text",
        key: "profession",
        index: "04",
        isOptional: true,
        enTranslation: "Your profession (optional)",
        frTranslation:
          "Quelle est ta profession\u202F? (\u202Ffacultatif\u202F)",
        answers: [],
        answer: {
          rawValue: user.profession ?? undefined,
        },
      },
      {
        id: 9,
        type: "text",
        key: "address",
        index: "05",
        enTranslation: "Your address",
        frTranslation: "Ton adresse postale",
        answers: [],
        answer: {
          rawValue: user.address ?? undefined,
        },
      },
      {
        id: 10,
        type: "text",
        key: "city",
        index: "06",
        enTranslation: "Your city",
        frTranslation: "Ta ville",
        answers: [],
        answer: {
          rawValue: user.city ?? undefined,
        },
      },
      {
        id: 11,
        type: "text",
        key: "zipCode",
        index: "07",
        isOptional: true,
        enTranslation: "Your zip code",
        frTranslation: "Ton code postal",
        answers: [],
        answer: {
          rawValue: user.zipCode ?? undefined,
        },
      },
      {
        id: 20,
        type: "text",
        key: "country",
        index: "08",
        subType: "autocomplete",
        enTranslation: "What is your birth country?",
        frTranslation: "Quel est ton pays de naissance\u202F?",
        answers: countries,
        answer: countryAnswer,
      },
      {
        id: 3,
        type: "radio",
        key: "selfDefinedGender",
        index: "09",
        enTranslation: "To which gender do you associate yourself?",
        frTranslation: "À quel genre t'associes-tu\u202F?",
        answers: selfDefinedGenderAnswers,
        answer: selfDefinedGenderAnswer,
      },
      {
        id: 4,
        type: "radio",
        key: "bornGender",
        index: "10",
        enTranslation: "What was your sex at birth?",
        frTranslation: "Quel sexe t’a été assigné à la naissance\u202F?",
        answers: genderAnswers,
        answer: genderAnswer,
      },
    ],
  };
};
