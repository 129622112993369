import React, { useState } from "react";
import { useRouteMatch } from "react-router-dom";
import Axios from "axios";
import { NotificationManager } from "react-notifications";
import {
  Footer,
  Header,
  Button,
  Field,
  PasswordValidator,
} from "../../components";
import useLanguage from "../../hooks/language";
import ENDPOINTS from "../../utils/endpoints";

const PasswordResetPage: React.FunctionComponent = () => {
  const { params } = useRouteMatch();
  const { translations } = useLanguage();
  const t = translations.resetPassword;

  // States

  const [submitted, setSubmitted] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [passwordValid, setPasswordValid] = useState(false);
  const [loading, setLoading] = useState(false);

  // Errors

  const passwordError = !passwordValid ? t.invalidPasswordError : null;
  const confirmationError =
    password !== passwordConfirmation ? t.passwordDontMatchError : null;
  const hasError = passwordError !== null || confirmationError !== null;
  const disabled =
    password.length === 0 ||
    passwordConfirmation.length === 0 ||
    (submitted && hasError);

  // Handlers

  const resetPassword = async () => {
    setSubmitted(true);

    if (hasError) return;

    setLoading(true);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { token } = params;

    try {
      await Axios.put(ENDPOINTS.RESET_PASSWORD, {
        token,
        newPassword: password,
      });
      NotificationManager.success(t.successMessage);
    } finally {
      setLoading(false);
    }
  };

  // Rendering

  return (
    <div className="page">
      <Header />

      <div className="preauth-container">
        <h2>{t.header}</h2>
        <div className="input-holder">
          <label>{t.passwordLabel}</label>
          <PasswordValidator password={password} setValid={setPasswordValid} />
          <Field
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            onPressEnter={resetPassword}
            error={submitted ? passwordError : null}
          />
        </div>
        <div className="input-holder">
          <label>{t.confirmationLabel}</label>
          <Field
            value={passwordConfirmation}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
            type="password"
            onPressEnter={resetPassword}
            error={submitted ? confirmationError : null}
          />
        </div>
        <Button disabled={disabled} loading={loading} onClick={resetPassword}>
          {t.changeButtonText}
        </Button>
      </div>

      <Footer />
    </div>
  );
};

export default PasswordResetPage;
