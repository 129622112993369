import React, { useState } from "react";
import LoginForm from "./login-form";
import SignupForm from "./signup-form";
import { Footer, Header } from "../../components";

import styles from "./login.module.css";

const LoginPage: React.FunctionComponent = () => {
  // States

  const [loginMode, setLoginMode] = useState(true);

  // Rendering

  return (
    <div className="page">
      <Header />

      <div className={`${styles.container} preauth-container`}>
        {!loginMode && <SignupForm setLoginMode={() => setLoginMode(true)} />}
        {loginMode && <LoginForm setSignupMode={() => setLoginMode(false)} />}
      </div>

      <Footer />
    </div>
  );
};

export default LoginPage;
