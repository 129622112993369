import MedicalTest, { getLocaleName } from "./medical-test";

export default interface SuggestedMedicalTest {
  id: number;
  medicalTest: MedicalTest;
}

// Utils methods

export const sortSuggestedMedicalTest = (
  suggestedMedicalTests: SuggestedMedicalTest[],
  locale: string
): SuggestedMedicalTest[] =>
  suggestedMedicalTests.sort(
    (a: SuggestedMedicalTest, b: SuggestedMedicalTest) =>
      getLocaleName(a.medicalTest, locale) >
      getLocaleName(b.medicalTest, locale)
        ? 1
        : -1
  );
