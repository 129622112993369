import Appointment, { isScheduled } from "./appointment";
import PhoneAppointment from "./phone-appointment";

export type TfaPreference = "sms" | "email";

export default interface User {
  id: number;
  ramqNumber: string;
  firstName: string;
  lastName: string;
  birthDate: string;
  phone: string;
  phoneNumberConfirmed: boolean;
  profession: string;
  tfaPreference?: TfaPreference;
  address: string;
  city: string;
  zipCode: string;
  country: string;
  selfDefinedGender: string;
  bornGender: string;
  lastAppointmentId: number;
  emailRemindersDisabled: boolean;
  age: number;
  hasToPay: boolean;
  appointments: Appointment[];
  phoneAppointments: PhoneAppointment[];
  lastUserQuestionnaireId: number;
  lastUserQuestionnaire?: any; // TODO: Create interface for UseQuestionnaire
}

export const geLastAppointment = ({
  appointments,
  lastAppointmentId,
}: User): Appointment | undefined => {
  return appointments.find(({ id }) => id === lastAppointmentId);
};

export const hasCompletedQuestionnaireWithoutScheduling = ({
  lastUserQuestionnaire,
  appointments,
}: User): boolean => {
  const lastQuestionnaireAppointment = appointments.find(
    ({ userQuestionnaire }) =>
      lastUserQuestionnaire && userQuestionnaire.id === lastUserQuestionnaire.id
  );
  return (
    lastUserQuestionnaire !== undefined &&
    lastUserQuestionnaire !== null &&
    !lastQuestionnaireAppointment
  );
};

export const expiredPendingQuestionnaire = (user: User): any | undefined => {
  return hasCompletedQuestionnaireWithoutScheduling(user) &&
    user.lastUserQuestionnaire!.isExpired
    ? user.lastUserQuestionnaire
    : undefined;
};

export const hasPaidLastQuestionnaire = ({
  lastUserQuestionnaire,
}: User): boolean => {
  return lastUserQuestionnaire && lastUserQuestionnaire.stripeOrder;
};

export const upcomingScreeningAppointment = (
  user: User
): Appointment | undefined => {
  const lastUserAppointment = geLastAppointment(user);
  return lastUserAppointment && isScheduled(lastUserAppointment)
    ? lastUserAppointment
    : undefined;
};
