import useLanguage from "../../../hooks/language";
import Clinic, { humanizeNextAvailableDate } from "../../../models/clinic";
import styles from "./clinic-card.module.css";
import React from "react";
interface ClinicCardProps {
  clinic: Clinic;
  hasDateSelection?: boolean;
  isVisible?: boolean;
}

const ClinicCard: React.FunctionComponent<ClinicCardProps> = ({
  clinic,
}: ClinicCardProps) => {
  const { translations, language } = useLanguage();
  const t = translations.humanizeDateFormatter;

  const nextAvailableDateString = humanizeNextAvailableDate(clinic, language);

  return (
    <div className={styles.cardContainer}>
      <div className={styles.infoContainer}>
        <h3>{clinic.name}</h3>
        <p>
          {nextAvailableDateString ? (
            <span>
              {t.nextAvailableTime} <b>{nextAvailableDateString}</b>
            </span>
          ) : (
            t.unknownDate
          )}
        </p>
        <a
          href={`https://maps.google.com/?q=${clinic.address}`}
          target="_blank"
          rel="noreferrer"
        >
          {clinic.address}
        </a>
      </div>
    </div>
  );
};

export default ClinicCard;
