import { useContext } from "react";
import AppContext from "../context/app";

type AuthTokenHook = [string | null, (token: string | null) => void];

const useAuthToken = (): AuthTokenHook => {
  const { authToken, setAuthToken } = useContext(AppContext);
  return [authToken, setAuthToken];
};

export default useAuthToken;
