import React, { useEffect, useState } from "react";
import Axios from "axios";
import { Loader } from "react-feather";
import Questionnaire, { extractAnswers } from "../../../models/questionnaire";
import {
  expiredPendingQuestionnaire,
  hasPaidLastQuestionnaire,
} from "../../../models/user";
import { QuestionnaireForm } from "../../../components";
import useLanguage from "../../../hooks/language";
import useAuthUser from "../../../hooks/auth-user";
import ENDPOINTS from "../../../utils/endpoints";

import styles from "./screening.module.css";

interface MedicalEvaluationProps {
  onSubmit: () => void;
}

const MedicalEvaluation: React.FunctionComponent<MedicalEvaluationProps> = ({
  onSubmit,
}: MedicalEvaluationProps) => {
  const [authUser, setAuthUser] = useAuthUser();
  const { translations } = useLanguage();
  const t = translations.questionnaireSection;

  // States

  const [questionnaire, setQuestionnaire] = useState<null | Questionnaire>(
    null
  );
  const [isSaving, setSaving] = useState(false);

  // Effects

  useEffect(() => {
    fetchQuestionnaire();
  }, []);

  // Network

  const fetchQuestionnaire = async () => {
    const { data } = await Axios.get(ENDPOINTS.QUESTIONNAIRE(1));
    setQuestionnaire(data);
  };

  const submitQuestionnaire = async (questionnaire: Questionnaire) => {
    if (!authUser) return;

    setSaving(true);
    const answers = extractAnswers(questionnaire);

    const expiredQuestionnaire =
      authUser && expiredPendingQuestionnaire(authUser);
    const hasAlreadyPaid =
      expiredQuestionnaire && hasPaidLastQuestionnaire(authUser!);

    const body = {
      userId: authUser.id,
      questionnaireId: questionnaire.id,
      fromId: hasAlreadyPaid ? expiredQuestionnaire.id : null,
      answers,
    };

    try {
      const { data } = await Axios.post(ENDPOINTS.USER_QUESTIONNAIRE, body);
      setAuthUser({
        ...authUser,
        lastUserQuestionnaire: data,
        lastUserQuestionnaireId: data.id,
      });
      onSubmit();
    } catch {
      setSaving(false);
    }
  };

  // Rendering

  const loadingElement = (
    <p className={styles.loader}>
      <Loader className="spinorama" />
      {t.loadingInfo}
    </p>
  );

  return (
    <div>
      <h2 className="container">{t.header}</h2>
      {!questionnaire && loadingElement}
      {questionnaire && (
        <QuestionnaireForm
          questionnaire={questionnaire}
          onCompleteQuestionnaire={submitQuestionnaire}
          loading={isSaving}
        />
      )}
    </div>
  );
};

export default MedicalEvaluation;
