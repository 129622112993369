import React, { useEffect, useState } from "react";
import Axios from "axios";
import { DateTime } from "luxon";
import Appointment, {
  canEdit,
  getMaxRetakeDate,
  getMaxScheduleDate,
  MAX_BOOK_APPOINTMENT_DATE_DAYS,
  shouldRetake,
} from "../../models/appointment";
import PhoneAppointment, {
  MAX_BOOK_PHONE_APPOINTMENT_DATE_DAYS,
} from "../../models/phone-appointment";
import { DateTimePicker } from "..";
import ENDPOINTS from "../../utils/endpoints";
import useAuthUser from "../../hooks/auth-user";
import { isAppointment } from "../../utils/appointment-helpers";

interface AppointmentDatetimePickerProps {
  appointment?: Appointment | PhoneAppointment;
  onSelectDate?: (isoDate: string | null) => void;
  className?: string;
  clinicId: number;
}

const AppointmentDatetimePicker: React.FunctionComponent<AppointmentDatetimePickerProps> = ({
  appointment,
  onSelectDate,
  className,
  clinicId,
}: AppointmentDatetimePickerProps) => {
  const [authUser] = useAuthUser();

  // states

  const [maxDate, setMaxDate] = useState<Date | undefined>();
  const [openedDates, setOpenedDates] = useState<string[] | undefined>();
  const [availableTimeSlot, setAvailableTimeSlot] = useState<
    string[] | undefined
  >([]);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedTime, setSelectedTime] = useState<string | undefined>();

  // Effects

  useEffect(() => {
    let calendarMaxDate: Date =
      authUser && authUser.lastUserQuestionnaire
        ? DateTime.fromISO(authUser.lastUserQuestionnaire.created_at)
            .plus({ days: MAX_BOOK_APPOINTMENT_DATE_DAYS })
            .toJSDate()
        : DateTime.local()
            .plus({ days: MAX_BOOK_APPOINTMENT_DATE_DAYS })
            .toJSDate();

    if (appointment && isAppointment(appointment)) {
      if (canEdit(appointment)) {
        calendarMaxDate = getMaxScheduleDate(appointment);
      } else if (shouldRetake(appointment)) {
        calendarMaxDate = getMaxRetakeDate(appointment);
      }
    } else if (appointment && !isAppointment(appointment)) {
      calendarMaxDate = DateTime.local()
        .plus({ days: MAX_BOOK_PHONE_APPOINTMENT_DATE_DAYS })
        .toJSDate();
    }

    setMaxDate(calendarMaxDate);
  }, [authUser, appointment]);

  useEffect(() => {
    fetchOpenedDates(new Date());
  }, [appointment]);

  useEffect(() => {
    setSelectedTime(undefined);
    fetchAvailableTime();
  }, [selectedDate]);

  useEffect(() => {
    if (!onSelectDate) return;

    if (!selectedTime) {
      onSelectDate(null);
      return;
    }

    const dateString = selectedDate.toISOString().split("T")[0];
    const dateTimeString = `${dateString}T${selectedTime}:00`;

    // TODO: Use the timezone of the clinic and not hardcoded and maybe move date creation to server
    const date = DateTime.fromISO(dateTimeString, {
      zone: "America/New_York",
    });

    onSelectDate(date.toISO());
  }, [selectedDate, selectedTime]);

  // Handlers

  const onCalendarNavigation = (date: Date) => {
    fetchOpenedDates(date);
  };

  // Network

  const fetchOpenedDates = async (date: Date) => {
    setOpenedDates(undefined);

    const year = date.getUTCFullYear();
    const month = date.getUTCMonth();
    const maybeClinicId =
      appointment && !isAppointment(appointment) ? undefined : clinicId;

    const url =
      appointment && !isAppointment(appointment)
        ? ENDPOINTS.PHONE_APPOINTMENT_AVAILABLE_DATE(
            year,
            month,
            ((appointment as unknown) as PhoneAppointment).type
          )
        : ENDPOINTS.APPOINTMENT_AVAILABLE_DATE(year, month, maybeClinicId);

    const { data } = await Axios.get(url);

    setOpenedDates(data);
  };

  const fetchAvailableTime = async () => {
    setAvailableTimeSlot(undefined);

    const maybeClinicId =
      appointment && !isAppointment(appointment) ? undefined : clinicId;

    const url =
      appointment && !isAppointment(appointment)
        ? ENDPOINTS.PHONE_APPOINTMENT_AVAILABLE_TIME(
            selectedDate,
            ((appointment as unknown) as PhoneAppointment).type
          )
        : ENDPOINTS.APPOINTMENT_AVAILABLE_TIME(selectedDate, maybeClinicId);

    let { data: slots } = await Axios.get(url);
    const sortedSlots = slots.sort();
    // Need to remove past available times if date is today
    const selectedDateIsToday = DateTime.fromJSDate(selectedDate).hasSame(
      DateTime.local(),
      "day"
    );
    if (selectedDateIsToday) {
      const now = DateTime.local();
      const currentAbsTime = now.hour + now.minute / 60;

      const filterPredicate = (time: string) => {
        const [hour, minute] = time.split(":");
        const absTime = parseInt(hour) + parseInt(minute) / 60;

        return absTime > currentAbsTime;
      };

      slots = sortedSlots.filter(filterPredicate);
    }
    setAvailableTimeSlot(sortedSlots);
  };

  // Rendering

  const minDate = new Date();
  minDate.setDate(minDate.getDate() - 1);

  return (
    <DateTimePicker
      className={className}
      minDate={minDate}
      maxDate={maxDate}
      openedDates={openedDates}
      onDateChange={setSelectedDate}
      onTimeChange={setSelectedTime}
      onCalendarNavigation={onCalendarNavigation}
      availableTimeSlots={availableTimeSlot}
    />
  );
};
export default AppointmentDatetimePicker;
