import React, {
  ChangeEvent,
  KeyboardEvent,
  RefObject,
  useState,
  useRef,
  cloneElement,
  useEffect,
} from "react";
import { Search, ChevronDown, X } from "react-feather";
import useLanguage from "../../hooks/language";
import MultipleSelectionField from "./multiple-selection-field";

import { Field } from "..";

import styles from "./select.module.css";

export type Selection = {
  value: string | number;
  key: number | string | null;
  display?: string;
  data?: any;
};

interface SelectInterface {
  className?: string;
  optionListClassName?: string;
  children?: React.ReactElement[];
  disabled?: boolean;
  loading?: boolean;
  search?: boolean;
  keepTyping?: boolean;
  multiple?: boolean;
  error?: string;
  value?: string | number;
  placeholder?: string;
  noResultPlaceholder?: string;
  selection?: Selection | undefined | Selection[] | null;
  defaultSelection?: Selection | Selection[];
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  inputRef?: RefObject<HTMLInputElement>;
  onSelect?: (selection: Selection | undefined | null | Selection[]) => void;
  onClear?: () => void;
}

const Select: React.FunctionComponent<SelectInterface> = ({
  className,
  optionListClassName,
  children,
  disabled = false,
  loading = false,
  search = false,
  keepTyping = false,
  multiple = false,
  noResultPlaceholder,
  error,
  placeholder,
  selection,
  defaultSelection,
  value,
  onChange,
  onSelect,
  onClear,
}: SelectInterface) => {
  const { translations } = useLanguage();
  const t = translations.select;

  // States and refs

  const fieldRef: RefObject<HTMLInputElement> = useRef(null);

  const [isFocused, setIsFocused] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [_value, setValue] = useState(value || "");

  let defaultSingleSelection = undefined;
  if (defaultSelection && !Array.isArray(defaultSelection) && !multiple)
    defaultSingleSelection = defaultSelection;
  if (selection && !Array.isArray(selection) && !multiple)
    defaultSingleSelection = selection;

  const [_singleSelection, setSingleSelection] = useState<
    Selection | undefined | null
  >(defaultSingleSelection);

  let defaultMultipleSelection: Selection[] = [];
  if (defaultSelection && Array.isArray(defaultSelection) && multiple)
    defaultMultipleSelection = defaultSelection;
  if (selection && Array.isArray(selection) && multiple)
    defaultMultipleSelection = selection;

  const [_multipleSelection, setMultipleSelection] = useState<Selection[]>(
    defaultMultipleSelection
  );

  // Effects

  useEffect(() => {
    if (_singleSelection)
      setValue(_singleSelection.display || _singleSelection.value);
    else setValue("");
    if (onSelect) onSelect(_singleSelection);
  }, [_singleSelection]);

  useEffect(() => {
    if (onSelect) onSelect(_multipleSelection);
  }, [_multipleSelection]);

  useEffect(() => {
    if (value) setValue(value);
  }, [value]);

  useEffect(() => {
    if (selection === undefined) return;

    if (selection === null && multiple) {
      setMultipleSelection([]);
      return;
    } else if (selection === null && !multiple) {
      setSingleSelection(undefined);
      return;
    }

    if (
      multiple &&
      Array.isArray(selection) &&
      _multipleSelection.map(mapSelectionToValue) !==
        selection.map(mapSelectionToValue)
    ) {
      setMultipleSelection(selection);
      return;
    } else if (
      !multiple &&
      !Array.isArray(selection) &&
      selection?.value !== _singleSelection?.value
    ) {
      setSingleSelection(selection);
      return;
    }
  }, [selection]);

  // Utils

  const incrementActiveIndex = () => {
    if (!children) return;

    const nextIndex = (activeIndex + 1) % children.length;
    setActiveIndex(nextIndex);
  };

  const decrementActiveIndex = () => {
    if (!children) return;

    let nextIndex = (activeIndex - 1) % children.length;
    if (nextIndex < 0) nextIndex = children.length - 1;
    setActiveIndex(nextIndex);
  };

  const isOptionSelected = (value: string) => {
    if (multiple) {
      return _multipleSelection.findIndex((s) => s.value === value) > -1;
    } else {
      return _singleSelection && _singleSelection.value === value;
    }
  };

  const mapElementToSelection = ({
    props,
    key,
  }: React.ReactElement): Selection => {
    return {
      key,
      value: props.value,
      // eslint-disable-next-line react/prop-types
      display: props.display,
      // eslint-disable-next-line react/prop-types
      data: props.data,
    };
  };

  const mapSelectionToValue = ({ value }: Selection): string | number => value;

  // Handlers

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "ArrowDown") {
      event.preventDefault();
      incrementActiveIndex();
    }
    if (event.key === "ArrowUp") {
      event.preventDefault();
      decrementActiveIndex();
    }
    if (event.key === "Enter") {
      event.preventDefault();
      onSelectOption(activeIndex);
    }
  };

  const _onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    if (!multiple) setSingleSelection(undefined);
    if (onChange) onChange(event);
  };

  const onSelectOption = (index: number) => {
    if (!children) return;

    if (multiple) {
      const optionIndex = _multipleSelection.findIndex(
        (s) => s.value === children[index].props.value
      );

      if (optionIndex > -1) {
        onRemoveOptionAtIndex(optionIndex);
      } else {
        setMultipleSelection([
          ..._multipleSelection,
          mapElementToSelection(children[index]),
        ]);
      }
      if (fieldRef && fieldRef.current) fieldRef.current.focus();
    } else {
      setSingleSelection(mapElementToSelection(children[index]));
      if (fieldRef && fieldRef.current) fieldRef.current.blur();
    }
  };

  const onRemoveOptionAtIndex = (index: number) => {
    const newSelection = [..._multipleSelection];
    newSelection.splice(index, 1);
    setMultipleSelection(newSelection);
  };

  const onClickClear = () => {
    setValue("");
    setMultipleSelection([]);
    setSingleSelection(undefined);
    if (fieldRef && fieldRef.current) fieldRef.current.focus();
    if (onClear) onClear();
  };

  // Rendering

  let rootClass = styles.holder;
  if (isFocused) rootClass += ` ${styles.focused}`;
  if (className) rootClass += ` ${className}`;

  let dataHolderClassName = styles.dataHolder;
  if (optionListClassName) dataHolderClassName += ` ${optionListClassName}`;

  const renderOption = (child: React.ReactElement, index: number) => {
    const augmentedProps = {
      active: activeIndex === index,
      selected: isOptionSelected(child.props.value),
      onHover: () => setActiveIndex(index),
      onClick: () => onSelectOption(index),
    };
    return cloneElement(child, augmentedProps);
  };

  let icon = <ChevronDown />;
  if (search) icon = <Search />;
  if (search && !multiple && _singleSelection) icon = <X />;

  const onIconClick =
    search && !multiple && _singleSelection ? onClickClear : undefined;

  return (
    <div className={rootClass}>
      {!multiple && (
        <Field
          inputRef={fieldRef}
          placeholder={placeholder}
          error={error}
          autoComplete="off"
          disabled={disabled}
          loading={loading}
          readOnly={!search}
          value={`${_value}`}
          icon={icon}
          onIconClick={onIconClick}
          onChange={_onChange}
          onKeyDown={onKeyDown}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
      )}

      {multiple && (
        <MultipleSelectionField
          selectedElements={_multipleSelection}
          placeholder={placeholder}
          error={error}
          disabled={disabled}
          loading={loading}
          readOnly={!search}
          value={`${_value}`}
          icon={icon}
          onRemoveElementAtIndex={onRemoveOptionAtIndex}
          onIconClick={onIconClick}
          onChange={_onChange}
          onKeyDown={onKeyDown}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
      )}

      {keepTyping && (
        <div className={styles.keepTyping}>
          {`${_value ?? ""}`.length === 0 ? t.startTyping : t.keepTyping}
        </div>
      )}

      {!keepTyping && children && children.length > 0 && (
        <div className={dataHolderClassName}>{children.map(renderOption)}</div>
      )}

      {!keepTyping && children && children.length === 0 && (
        <div className={styles.noData}>
          {noResultPlaceholder ?? t.noResults}
        </div>
      )}
    </div>
  );
};

export default Select;
