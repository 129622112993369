import { DateTime } from "luxon";
import ChosenMedicalTest from "./chosen-medical-test";
import PhoneAppointment from "./phone-appointment";
import Clinic from "./clinic";

export type AppointmentStatus =
  | "SCHEDULED"
  | "IN_WAITING_ROOM"
  | "IN_CABIN"
  | "MEDICAL_RESULTS_PENDING"
  | "MEDICAL_RESULTS_TO_SEND"
  | "MEDICAL_PART_RESULTS_PENDING"
  | "MEDICAL_RESULTS_SAVED"
  | "MEDICAL_RESULTS_SENT"
  | "RETAKE"
  | "RETAKE_SCHEDULED"
  | "RETAKE_REJECTED"
  | "CANCELED"
  | "CANCELED_FOR_STAFF_ABSENCE";

export default interface Appointment {
  id: number;
  created_at: string;
  datetime: string;
  clinic: Clinic;
  sendResultsDatetime: string;
  status: AppointmentStatus;
  isMissed: boolean;
  isMissedAndCannotRetake: boolean;
  suggestionsAreApproved: boolean;
  canTakeSatisfactionQuestionnaire: boolean;
  chosenMedicalTests: ChosenMedicalTest[];
  followUpPhoneAppointments: PhoneAppointment[];
  resultsSeen: boolean;
  canRescheduleFreely: boolean;
  userQuestionnaire: any; // TODO: Create interface for UseQuestionnaire
}

// Constants

export const MAX_BOOK_APPOINTMENT_DATE_DAYS = 45;
export const APPOINTMENT_TYPE = "appointment";

// Helper methods

export const isScheduled = ({ status }: Appointment): boolean =>
  status === "SCHEDULED";

export const isCanceled = ({ status }: Appointment): boolean =>
  status === "CANCELED" || status === "CANCELED_FOR_STAFF_ABSENCE";

export const isCanceledForStaffAbsence = ({ status }: Appointment): boolean =>
  status === "CANCELED_FOR_STAFF_ABSENCE";

export const isCanceledNotForStaffAbsence = ({
  status,
}: Appointment): boolean => status === "CANCELED";

export const isMissed = ({
  isMissed,
  isMissedAndCannotRetake,
}: Appointment): boolean => isMissed && !isMissedAndCannotRetake;

export const isMissedAndCannotRetake = ({
  isMissedAndCannotRetake,
}: Appointment): boolean => isMissedAndCannotRetake;

export const isCompleted = ({ status }: Appointment): boolean =>
  [
    "IN_WAITING_ROOM",
    "IN_CABIN",
    "MEDICAL_RESULTS_PENDING",
    "MEDICAL_RESULTS_TO_SEND",
    "MEDICAL_PART_RESULTS_PENDING",
    "MEDICAL_RESULTS_SAVED",
    "MEDICAL_RESULTS_SENT",
    "RETAKE",
    "RETAKE_SCHEDULED",
    "RETAKE_REJECTED",
  ].indexOf(status) >= 0;

export const hasReceivedResults = ({ status }: Appointment): boolean =>
  [
    "MEDICAL_RESULTS_SENT",
    "MEDICAL_PART_RESULTS_PENDING",
    "RETAKE",
    "RETAKE_SCHEDULED",
    "RETAKE_REJECTED",
  ].indexOf(status) >= 0;

export const hasPendingResults = ({ status }: Appointment): boolean =>
  status === "MEDICAL_PART_RESULTS_PENDING";

export const hasAPositiveResult = (appointment: Appointment): boolean =>
  hasReceivedResults(appointment) &&
  appointment.chosenMedicalTests.some(({ result }) => result === "ABNORMAL");

export const canEdit = (appointment: Appointment): boolean =>
  !isCompleted(appointment) &&
  !isCanceledNotForStaffAbsence(appointment) &&
  !isMissedAndCannotRetake(appointment);

export const shouldRetake = ({
  status,
  chosenMedicalTests,
}: Appointment): boolean =>
  status === "RETAKE" &&
  chosenMedicalTests.some(({ result }) => result === "RETAKE");

export const getPhoneAppointmentToSchedule = ({
  followUpPhoneAppointments,
}: Appointment): PhoneAppointment | undefined =>
  (followUpPhoneAppointments ?? []).find(({ status }) => status === "INVITED");

export const isUpcoming = ({ status, isMissed }: Appointment): boolean =>
  status === "SCHEDULED" && !isMissed;

export const isAppointment = (
  app: Appointment | PhoneAppointment
): app is Appointment => {
  return "userQuestionnaire" in app;
};

export const getMaxScheduleDate = ({
  userQuestionnaire,
  created_at,
}: Appointment): Date => {
  const userQuestionnaireCreationDate = userQuestionnaire
    ? DateTime.fromISO(userQuestionnaire.created_at)
    : DateTime.fromISO(created_at);
  return userQuestionnaireCreationDate
    .plus({ days: MAX_BOOK_APPOINTMENT_DATE_DAYS })
    .toJSDate();
};

export const getMaxRetakeDate = ({
  sendResultsDatetime,
  datetime,
}: Appointment): Date => {
  const maxRetakeDate = sendResultsDatetime
    ? DateTime.fromISO(sendResultsDatetime)
    : DateTime.fromISO(datetime);
  return maxRetakeDate
    .plus({ days: MAX_BOOK_APPOINTMENT_DATE_DAYS })
    .toJSDate();
};
